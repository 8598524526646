body {
    background-color: rgb(22, 22, 22);
    font-family: 'Readex Pro', sans-serif;
}

.trainer-divider {
    height: 20px;
}

.gto-trainer-right {
    width: 100%;
    background-color: rgb(25, 36, 36, 0.25);
    grid-column: 2;
    grid-row: 1;
    /* height: 1px;
  margin-left: 5%; */

    border-left: 1px solid
        linear-gradient(180deg, rgba(255, 255, 255, 0.2) 100%, rgba(255, 255, 255, 0) 100%);
}

.gto-trainer-single-table {
    width: 100%;
    max-width: 800px;
}
.gto-trainer-left {
    width: 100%;
    display: flex;
    justify-content: center;
}

.clear {
    z-index: 999;
}

.gto-trainer-left p {
    font-family: 'Readex Pro', sans-serif;
    font-size: 15px;
    margin-left: 0px;
    margin-bottom: 5px;

    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.gto-trainer-right p {
    font-family: 'Readex Pro', sans-serif;
    font-size: 11px;

    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.12px;
    /* color: #4b4a4a; */
    text-transform: uppercase;
}

.gto-trainer-right button {
    min-width: 15%;
    width: auto;
    height: 32px;
    border-radius: 50px;
    background-color: #f2f3f8;

    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(67, 66, 66, 0.87);
    border: solid 0px #388080;

    outline: none;
    /*overflow: hidden;*/
    /* text-overflow: ellipsis; */
    white-space: nowrap;
}

.gto-trainer-left button {
    width: auto;
    /*height: 42px;*/
    min-width: 80px;
    height: 40px;

    /*padding-left: 30px;*/
    /*padding-right: 30px;*/
    font-size: 14px;
    font-weight: normal;

    white-space: nowrap;
    background: #b7b7b7;

    box-sizing: border-box;
    border-radius: 10px;
    color: black;
    /*background-image: linear-gradient(to top, rgb(183, 183, 183), rgb(215, 215, 215));*/
    background: linear-gradient(360deg, #b7b7b7 0%, #d7d7d7 100%);
    border: 2px solid #646464;
}
.gto-trainer-left button:hover {
    background: white;
    /*background-image: linear-gradient(to top,rgb(11,133,120,1),rgb(9,113,102,1));*/
}

.gto-trainer-loader {
    position: absolute;
    /*align-items: center;*/
    /*text-align: center;*/
    /*width: 100%;*/

    /*padding: 50px;*/

    /*height: 50px;*/

    margin: auto;

    margin-top: 30vh;
}

/*.gto-trainer-loader div {*/
/*    margin: 0 auto;*/
/*    display: block;*/
/*    !*margin-top: -30px;*!*/

/*}*/
.gto-trainer-menu {
    margin-top: 10px;
    display: flex;
}
.final-table {
    padding: 15px;
    position: absolute;
    color: #388080;
    font-size: 14px;
    /*font-weight: bold;*/
    font-family: 'Readex Pro';
}
.gto-trainer-content {
    max-width: 1300px;
}

.mobile-overflow {
    overflow-x: hidden;
    height: 100vh;
}
@media (max-width: 1800.98px) {
    .gto-trainer-content {
        max-width: 1300px;
    }
}

@media (max-height: 970px) {
    .gto-trainer-content {
        max-width: 1300px;
    }
}

.player-bubble {
    text-align: center;
    display: block;
    width: 100%;
}

.player-bubble .position {
    color: #388080;
    font-size: 14px;
    font-weight: bold;
    font-family: 'Readex Pro';
}

.player-bubble .amount {
    font-size: 14px;
    /*font-weight: bold;*/
    font-family: 'Readex Pro';
}

.gto-trainer-table-info {
    position: absolute;
    font-size: 14px;
    /*font-weight: bold;*/
    font-family: 'Readex Pro';
}

.grid-4-trainers {
    /* background-color: rgba(240, 240, 240, 0.9); */
    border-radius: 8px;
    display: grid;
    width: 100%;
    justify-content: center;
    grid-gap: 4%;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 40px;
}

.grid-2-trainers {
    /* background-color: rgba(240, 240, 240, 0.9); */
    border-radius: 8px;
    display: grid;
    width: 100%;
    justify-content: center;
    grid-gap: 8%;
    grid-template-columns: 1fr 1fr;
}

@media (max-width: 1920.98px) and (max-height: 975px) {
    .grid-4-trainers {
        max-width: 1150px;
    }

    .grid-2-trainers {
        max-width: 1300px;
    }
}

@media (max-width: 1400px) and (max-height: 975px) {
    .grid-4-trainers {
        max-width: 1150px;
    }

    .grid-2-trainers {
        max-width: 1300px;
    }
}

.resolution {
    position: fixed;
}
.TrainerContainer {
    /*margin-top: 10px;*/
    /*padding: 0 50px;*/
    /*background: red*/

    max-height: 50vh;
}
/*@media (max-width: 1980.98px) {*/

/*    .TrainerContainer {*/
/*        max-width: 500px;*/
/*    }*/

/*}*/
/*@media (max-width: 1500.00px) {*/

/*    .TrainerContainer {*/
/*        max-width: 400px;*/
/*    }*/

/*}*/

.custom + .custom {
    margin-left: -33.33%;
    grid-column: 2;
    grid-row: 3;
}
.icm-table {
    background: rgb(22, 71, 35, 0.1) !important;
    border-radius: 10px;
    /*background: red !important;*/
}

.progressbar {
    content: '';
    /* width: 40px; */
    height: 8px;
    background: red;

    position: absolute;
    animation: loader 10s;
    -webkit-animation: loader 10s;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    transition-timing-function: linear;
    -webkit-transition-timing-function: linear;
    bottom: 0px;

    /*margin-bottom: 28px;*/
    border-radius: 10px;
    /*margin: 0px 5px;*/
}

@keyframes loader {
    0% {
        width: 100%;
        left: 0%;
        right: 0;
        background: #0f0;
    }
    /*50% { width: 100%;left:0;right:0}*/
    99% {
        width: 0%;
        left: 0%;
        right: 0;
        background: #f00;
    }
}

/*@-webkit-keyframes loader {*/
/*    0% {width: 0%;left:0;right:0}*/
/*    50% { width: 100%;left:0;right:0}*/
/*    99% { width: 0%;left:100%;right:0}*/
/*}*/

/*@keyframes progressbar-countdown {*/
/*    0% {*/
/*        width: 100%;*/
/*        background: #0F0;*/
/*    }*/
/*    100% {*/
/*        width: 0%;*/
/*        background: #F00;*/
/*    }*/
/*}*/

/*@media (max-width: 560px) {*/
/*    .trainer-panel-container {*/
/*        width: 100%;*/
/*    }*/
/*}*/
.trainer-panel-left-container {
    grid-column: 1;
    grid-row: 1;

    /* Multiple backgrounds */
}

.trainer-panel-left {
    background-color: rgb(37, 40, 44, 0.35);
    border-radius: 10px;
    padding: 15px;
    /* Multiple backgrounds */
    grid-column: 1;
    grid-row: 1;
}
.summary-panel-left {
    background-color: rgb(37, 40, 44, 0.35);
    border-radius: 10px;

    /* Multiple backgrounds */
    grid-column: 1;
    width: 100%;
    grid-row: 1;
}
.padding-15 {
    padding: 15px;
}
.padding-15-y {
    padding-top: 2px;
    padding-bottom: 15px;
}

.summary-panel-right {
    background-color: rgb(37, 40, 44, 0.35);
    border-radius: 10px;
    padding: 15px;
    /* Multiple backgrounds */
    grid-column: 2;
    grid-row: 1;
    width: 100%;
}
.index-hand {
    font-size: 8px;
    width: 12px;
    color: grey;
    margin-left: 10px;
}

.summary-hand {
    background-color: rgba(37, 40, 44, 0.85);

    /*font-weight: bold;*/
    font-family: 'Readex Pro', sans-serif;
    font-size: 13px;
    padding: 2px;
    align-items: center;

    border-radius: 5px;
    border: 1px solid rgba(37, 40, 44, 0.85);

    margin-bottom: 8px;
    display: flex;
}

.summary-score {
    /*background-color: rgba(63, 68, 71, 0.85);*/

    /*font-weight: bold;*/
    font-family: 'Readex Pro', sans-serif;
    font-size: 13px;
    align-items: center;

    border-radius: 5px;
    /*border: 1px solid rgba(37, 40, 44, 0.85);*/

    font-size: 13px;

    border-radius: 5px;
    border: 1px solid rgba(37, 40, 44, 0.85);
    margin-bottom: 8px;
    justify-content: space-between;
    padding: 6px;
    padding-left: 10%;
    padding-right: 10%;
}

.summary-score-stars {
    /*background-color: rgba(63, 68, 71, 0.85);*/

    /*font-weight: bold;*/
    font-family: 'Readex Pro', sans-serif;
    font-size: 13px;
    align-items: center;
    font-size: 13px;
    justify-content: right;
    display: block;
    text-align: center;
}
.summary-score-highlighted {
    font-size: 24px;
    font-weight: bold;
}
.summary-score-header {
    color: #6c6c6c;
}
.summary-cards {
    /*padding: 3px;*/
    margin-top: 1px;
}
.summary-table {
    display: table;
    width: 100%;
    text-align: center;
    border-collapse: collapse;
}

.game-exit {
    position: absolute;
    z-index: 999;
    right: 5px;
}

.game-timer {
    position: absolute;
    z-index: 999;
    right: 5px;
    white-space: nowrap;
    box-sizing: border-box;
    width: 65px;
    height: 50px;

    font-size: 13px;
    text-align: center;
    line-height: 30px;
    padding: 7px 7px;
    margin-top: 125px;

    justify-content: center;

    box-sizing: border-box;
    border-radius: 15px;
}

.game-results-widget {
    position: absolute;
    z-index: 999;
    right: 5px;
    white-space: nowrap;
    box-sizing: border-box;
    font-size: 13px;
    width: 65px;
    height: 50px;

    font-weight: lighter;

    text-align: center;
    line-height: 30px;
    padding: 7px 7px;

    justify-content: center;
    border-radius: 15px;

    background: none !important;

    margin-top: 195px;
}

.game-exit button {
    white-space: nowrap;

    box-sizing: border-box;

    width: 65px;
    height: 110px;
    font-family: 'Readex Pro';
    display: flex;

    padding: 7px 7px;
    margin-top: 5px;

    border: 1px solid #388080;
    box-sizing: border-box;
    border-radius: 15px;
    color: white !important;
    background: none !important;

    display: block;
    justify-content: center;
    align-items: center;
    word-wrap: break-word;
    /*background-image: linear-gradient(to top, rgb(183, 183, 183), rgb(215, 215, 215));*/
}

.game-exit button:hover {
}

.repeat {
    font-size: 13px;
    line-height: 40px;
    font-family: 'Readex Pro', sans-serif;
    min-width: 80px;
    height: 40px;
    cursor: pointer;
}
.stop-learning {
    font-size: 12px;
    margin-top: 7px;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.click-to-reveal {
    position: relative;
    z-index: 100;
    /*background: red;*/
    bottom: 200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 12px;
    align-items: center;
    padding-right: 40px;
    min-width: 120px;
    text-transform: uppercase;
    height: 16px;
    /*border: 1px solid #388080;*/
    box-sizing: border-box;
    /*border-radius: 64px;*/
    /*background-image: linear-gradient(to top, rgb(183, 183, 183, 0.2), rgb(215, 215, 215, 0.2));*/
    color: #414141;
    /*background: none !important;*/
}

.summary-hand-decision {
    color: grey;
    font-size: 12px;
}
.summary-hand-row {
    display: table-row;
}
.summary-hand-viewer {
    /*padding-top: 0px;*/
    /*padding-left: 20px;*/
    /*padding-right: 30px;*/
    /*position: absolute;*/
    /*background-color: rgba(43, 46, 50, 1);*/
    /*width: 600px;*/

    /*z-index: 999;*/
    /*border-radius: 10px;*/
}
.summary-hand-cell {
    width: 12%;
}
.summary-type-cell {
    width: 20%;
}

.summary-hand-row:nth-child(even) {
    background-color: rgba(43, 46, 50, 0.85);
}
.summary-page {
    font-size: 12px;
}
.summary-headers {
    font-size: 12px;
    margin-bottom: 5px;
    margin-bottom: 5px;
    color: grey !important;
}
.summary-hand:hover {
    /*background: #388080;*/
    background-color: rgba(56, 128, 128, 0.35);

    color: white !important;
    /*background-color: rgba(73, 76, 78, 0.85);*/
}

.summary-hand-active {
    border: 1px solid #388080;
}

.trainer-panel-right {
    border-radius: 10px;
    grid-column: 2;
    grid-row: 1;
}

.trainer-panel-right-inside {
    padding: 15px;
    background-color: rgb(37, 40, 44, 0.35);
    border-radius: 10px;
    grid-column: 2;
    grid-row: 2;
    padding: 15px;
    margin-bottom: 15px;
}
.trainer-header {
    font-size: 14px;
    /*font-weight: bold;*/
    font-family: 'Readex Pro', sans-serif;
    margin-left: 8px;
}
.trainer-dot {
    height: 15px;
    width: 15px;
    margin: 2px;
    background: #388080;
    border-radius: 10px;
}
.trainer-panel-dropdown {
    background-color: rgba(37, 40, 44, 0.85);

    color: #388080;
    font-size: 14px;
    /*font-weight: bold;*/
    font-family: 'Readex Pro', sans-serif;
    padding: 15px;
    border-radius: 5px;
    &.hover {
        color: red;
    }
}

.trainer-panel-dropdown-item-active {
    background: #388080;
}
.multi-item {
    padding-left: 5px;
    padding-right: 2px;
    border-radius: 5px;
    background-color: rgba(89, 89, 89, 0.45);
}
.trainer-panel-dropdown-item {
    text-align: center;
    /*position: relative !important;*/
    background-color: rgba(37, 40, 44, 1);
}
.dropdown-item {
    /*background: red;*/
    margin: 0px;
}
.trainer-panel-body {
    font-size: 13px;
    /*font-weight: bold;*/
    /*margin-left: 8px;*/
    font-family: 'Readex Pro', sans-serif;
    margin-top: 10px;
    /*background: red;*/
}

.trainer-button-start {
    border: 1px solid #388080;
    color: white;
    height: 50px;
}

.trainer-button-start:hover {
    background: #388080 !important;
    color: white;
}
.dropdown-item:hover {
    background: #388080;
}

.chart-icon {
    position: absolute;
    /*background: rgba(0,0,0,0.3);*/
    border-radius: 5px;
    right: -5px;
    top: -10px;
    /*padding: 0px 2px 2px 2px;*/
    cursor: pointer;
}
.beta {
    /*content: 'beta';*/
    font-weight: bold;
    padding: 3px;
    float: right;
    margin-left: 5px;

    /*margin-top: -25px;*/
    /*margin-right: 15px;*/
    font-size: 10px;

    background-color: #cf0303;
    color: white;
    border-radius: 5px;
    opacity: 0.9;
    /*border-top-left-radius: 10px;*/
    /*border-top-right-radius: 10px;*/
}

.beta-left {
    font-weight: bold;
    padding: 3px;

    /*margin-right: 15px;*/
    font-size: 10px;

    background-color: #cf0303;
    color: white;
    border-radius: 5px;
    opacity: 0.9;
    height: 20px;
    margin-right: 10px;
}

.beta-trainer {
    margin-top: 5px;
    height: 20px;
    line-height: 13px;
}

.grid-learning {
    width: 100%;

    border-radius: 8px;
    display: grid;
    grid-gap: 4%;
    grid-template-columns: 1fr 1fr;

    /*max-width: 1100px;*/
}

.blur {
    filter: grayscale(100%) blur(15px) invert() hue-rotate(150deg);
}

@-webkit-keyframes rotate-center {
    0% {
        opacity: 0.1;
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    50% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    100% {
        opacity: 1;
    }
}
@keyframes rotate-center {
    0% {
        opacity: 0.1;
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    50% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.trainer-multi-select {
    color: rgba(37, 40, 44, 0.85);
    color: black;
}

.trainer-multi-select-container {
    margin-top: 0.5rem;
    display: flex;
}
.trainer-filter-header {
    /*background: #25282CD8;*/
    width: 200px;
    border-radius: 5px;
    margin-right: 6px;
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 4px;
}

@media (max-width: 558px) and (max-height: 975px) {
    .trainer-filter-header {
        width: unset;
    }
}

.trainer-filter-header .tooltiptext {
    visibility: hidden;
    color: black;
    text-align: center;
    padding: 5px;
    padding-left: 8px;
    padding-right: 8px;
    line-height: 20px;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    margin-top: 80px;
    margin-left: 3px;
    background: #fafafa;
    border-radius: 8px;
    height: auto;
    transition-delay: 0.3s;
    font-weight: normal;
    font-size: 12px;
    max-width: 230px;
    white-space: pre-wrap;
}

.trainer-filter-header:hover .tooltiptext {
    visibility: visible;
    transition-delay: 0.4s;
}
.current-pot-container {
    position: absolute;
    display: block;
    right: 0px;
}
.current-pot {
    font-size: 13px;
    display: flex;
}

.current-pot:last-child {
    margin-top: 16px;
}

.table-adjuster-25 {
    /*opacity: 0.7;*/
    /*filter: saturate(1);*/
    /*filter: brightness(110%);*/
}
.table-adjuster-10 {
    /*opacity: 0.8;*/
    filter: saturate(1.18);
    /*filter: brightness(110%);*/
}

.table-adjuster-50 {
    filter: opacity(80%);
    /*filter: saturate(0);*/
}

.table-adjuster-Fi {
    /*opacity: 0.9;*/
    filter: saturate(1.3);
}

.current-pot-label {
    width: 100px;
    font-size: 13px;
    display: flex;
    margin-right: 5px;
    /*align-items: flex-end;*/
    justify-content: right;
}
.current-pot-value {
    /*width: 80px;*/
    margin-right: 10px;
    font-size: 13px;
    display: flex;
    margin-left: 10px;
    /*align-items: flex-end;*/
    justify-content: left;
}
