.red {
    background-color: red;
    color: white;
}

.green {
    background-color: green;
    color: white;
}

.pokermatrix {
    border-radius: 10px;
}

.sub-header {
    cursor: default;
    margin-left: 10px;
    margin-top: 4px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.06px;
    text-align: left;
    color: rgba(255, 255, 255, 0.8);
    white-space: nowrap;
}

.tile {
    position: relative;
    border-radius: 1px;
    height: 24px;
    width: 7.69%;
    cursor: pointer;
    border-right: 1px solid #161616;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.tile:last-child {
    border-right: none;
}

.tileki {
    width: 100%;
    height: 24px;
    display: inline-flex;
}

@media (min-width: 1600px) {
    .tileki, .tile {
        height: 32px;
    }

    .tileInside {
        font-size: 16px !important;
    }
}

.tile-allin {
    background-image: linear-gradient(to top, rgb(132, 18, 7), rgb(111, 15, 6));
    width: 15%;
}

.tile-raise {
    width: 35%;
    background-image: linear-gradient(to top, rgb(224, 97, 6), rgb(186, 78, 0));
}

.tile-call {
    width: 30%;
    background-image: linear-gradient(to top, rgb(11, 133, 120), rgb(9, 113, 102));
}

.tile-fold {
    width: 20%;
    background-color: rgb(128, 128, 128);
}

rect {
    background: green;
}

rect.rectClass {
    stroke: #000066;
    fill: #00cc00;
    border-bottom-left-radius: 10px;
}

.tileInside {
    position: absolute;
    color: white;
    width: 100%;
    height: 100%;
    color: hsla(0, 0%, 100%, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    font-weight: 400 !important;
    font-size: 13px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    cursor: pointer;

    text-shadow: 1px 1px 2px #000000;
    margin-top: -1px;
}

.highlighted-hand {
    border: 1px solid rgba(255, 255, 255, 0.6);
}

.border-matrix {
    /*z-index: 88;*/
    font-weight: normal;
    font-size: 3em !important;
    opacity: 1;
    height: 98%;
    width: 98%;
    position: absolute;
    color: white;
}

.border-radius-bottom-left {
    border-bottom-left-radius: 10px;
}

.border-radius-top-left {
    border-top-left-radius: 10px;
}

.border-radius-top-right {
    border-top-right-radius: 10px;
}

.border-radius-bottom-right {
    border-bottom-right-radius: 10px;
}

.matrix {
    width: 100%;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
}

.watermark {
    font-weight: normal;
    font-size: 2em !important;
    opacity: 0.2;
    height: 100%;
    width: 100%;
    position: absolute;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.watermark-random {
    font-weight: normal;
    font-size: 3em !important;
    opacity: 0.15;
    height: 100%;
    width: 100%;
    position: absolute;
    color: white;
    display: flex;
    transform-origin: 0 0;
    align-items: flex-end;
    justify-content: center;
    margin-left: 5px;
}

.rowMatrix {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #161616;
}

.rowMatrix:last-child {
    border-bottom: none;
}

.actionButtons {
    width: auto;
}

.buttonAllin,
.buttonRaise,
.buttonCall,
.buttonFold,
.buttonReset {
    width: 130px;
    height: 40px;
    background-color: #f2f3f8;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(67, 66, 66, 0.87);
    border: solid 0px #388080;
    margin-top: 8px;
    outline: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.buttonAllin {
    background-color: #831405;
    color: white;
}

.actionButton {
    display: inline-flex;
}

.percentsContainer {
    text-align: left;
    align-items: center;
    display: flex;
}

.percCon {
    justify-content: space-between;
}

.title-container {
    position: relative;
    display: flex;
    width: 100%;
    margin-bottom: 8px;
}

.title {
    display: inline-flex;
    white-space: nowrap;
    height: 100%;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: normal;
    text-align: left;
    color: rgba(255, 255, 255, 0.9);
    width: 100%;
    justify-content: space-between;
}

.evTable {
    font-family: sans-serif;
    margin-left: 10px;
    display: flex;
    width: 100%;
    border-radius: 3px;
    float: right;
}

.evTile {
    width: 20%;
    float: right;
    font-family: 'Readex Pro', sans-serif;
    font-size: 13px;
    margin: auto;
    text-align: center;
    color: white;
    padding: 1px;
}

.evDetails {
    border: 1px solid rgb(92, 92, 92, 0.6);
    display: flex;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.06px;
    text-align: center;
    color: rgba(255, 255, 255, 0.9);
}

.evLabel {
    float: right;
    font-family: 'Readex Pro', sans-serif;
    font-size: 12px;
    margin: auto;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: rgba(255, 255, 255, 0.5);
    text-align: right;
    margin-right: 4px;
}

.titleHeader {
    font-weight: bold;
    align-self: flex-end;
}

.notEv {
    display: block;
    width: 100%;
    height: 100%;
    margin: auto;
    min-height: 54px;
    text-align: left;
    align-items: left;
    justify-content: left;
}

.evContainer {
    min-height: 38px;
    margin-top: 8px;
    /*align-self: flex-end;*/
    /*display: flex;*/
}

.evContainer > div {
    min-width: 100px;
}

@media (max-width: 981px) {
    .evContainer {
        display: none;
    }

    .idmatrix {
        display: none;
    }
}

.ev-allin {
    border-right: 1px solid #161616;
    width: 44px;
    background-image: linear-gradient(to top, rgb(132, 18, 7, 1), rgb(111, 15, 6, 1));
    padding: 5px;
    font-family: 'Readex Pro', sans-serif;
}

.ev-raise {
    border-right: 1px solid #161616;
    width: 44px;
    background-image: linear-gradient(to top, rgb(224, 97, 6, 1), rgb(186, 78, 0, 1));
    padding: 5px;
    font-family: 'Readex Pro', sans-serif;
}

.ev-call {
    border-right: 1px solid #161616;
    width: 44px;
    background-image: linear-gradient(to top, rgb(11, 133, 120, 1), rgb(9, 113, 102, 1));
    padding: 5px;
    font-family: 'Readex Pro', sans-serif;
}

.ev-fold {
    width: 43px;
    padding: 5px;
    background-image: linear-gradient(to top, rgb(128, 128, 128, 1), rgb(128, 128, 128, 1));
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
    font-family: 'Readex Pro', sans-serif;
}

.percents {
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    border: 1px solid grey;
    padding: 4px 8px 4px;
    border-radius: 5px;
    margin-right: 4px;
    transition: transform 5s;
}

.allin {
    color: #831405;
    cursor: default;
    height: 12px;
    border-radius: 50%;
    width: 12px;
    background-color: rgba(132, 20, 5, 1);
    border: solid 1px rgba(255, 255, 255, 0.5);
}

.raise {
    color: #e06106;
    cursor: default;
    height: 12px;
    border-radius: 50%;
    width: 12px !important;
    background-color: rgba(224, 97, 6, 1);
    border: solid 1px rgba(255, 255, 255, 0.5);
}

.opacity05 {
    opacity: 0.5;
}

.label {
    cursor: default;
    margin-left: 6px;
    margin-right: 8px;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: 0.06px;
    text-align: left;
    white-space: nowrap;
}

.call {
    cursor: default;
    height: 12px;
    border-radius: 50%;
    width: 12px;
    background-color: #0b8578;
    border: solid 1px rgba(255, 255, 255, 0.5);
}

.fold {
    cursor: default;
    height: 12px;
    border-radius: 50%;
    width: 12px;
    background-color: rgba(173, 173, 173, 1);
    border: solid 1px rgba(255, 255, 255, 0.5);
}

.buttonRaise {
    background-color: #e06106;
    color: white;
}

.buttonCall {
    background-color: #388080;
    color: white;
}

.buttonFold {
    background-color: #054783;
    color: white;
}

label {
    margin-right: 5px;
    font-size: small;
}

.percentageSelect {
    margin-left: 5px;
}

.tile::selection {
    color: none;
    background: none;
}

.tileInside::selection {
    color: none;
    background: none;
}

.buttonReset {
    background-color: grey;
    color: white;
}

.adminMatrixContainer {
    width: 100%;
}

.converTo {
    display: flex;
    color: grey;
    font-size: 12px;
    text-transform: uppercase;
    padding: 6px;
    cursor: pointer;
    align-content: center;
    width: 45px;
    font-family: WorkSans;
}

.matrixFooter {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: space-between;
}

.counter {
    display: flex;
    color: grey;
    font-size: 12px;
    text-transform: uppercase;
    padding: 6px;
    align-content: center;
    width: 75px;
}

.matrixContainer {
    background: #202328;
    border-radius: 10px;
    padding: 16px;
    margin-bottom: 24px;
    max-width: 700px;
}

.eq-value {
    padding: 4px;
    font-size: 12px;
    transform: translateY(8px);
    margin: 8px 0;
    border-radius: 8px;
    width: 60px;
    height: 44px;
    text-align: center;
}
