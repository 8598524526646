/*body {*/
/*  background-color: #6f9;*/
/*}*/
@keyframes scale-up-ver-top {
    0% {
        transform: scaleY(0.7);
        transform-origin: 100% 0;
    }
    100% {
        transform: scaleY(1);
        transform-origin: 100% 0;
    }
}

.copyDropdown {
    position: absolute;
    background: #161616;
    /*margin: 10px;*/
    margin-top: 54px;
    /*margin-left: 18px;*/
    border: 1px solid grey;
    border-radius: 6px;

    display: flex;
    align-content: space-between;
    justify-content: space-between;
    z-index: 500;

    margin-left: -10px;
    width: 200px;
    /*animation:scale-up-ver-top .2s cubic-bezier(.39,.575,.565,1.000) both*/
}

.handConvertedText {
    overflow-y: scroll;
    overflow-wrap: anywhere;
    overflow-x: hidden;
}

.handConverted {
    position: absolute;
    background: #161616;
    /*margin: 10px;*/

    /*.row {*/
    /*  display: flex;*/
    /*  flex-direction: row;*/
    /*}*/
    border: 1px solid grey;
    border-radius: 6px;
    margin-top: 114px;
    margin-left: 18px;
    display: flex;
    align-content: space-between;
    justify-content: space-between;
    z-index: 449;

    /*.column {*/
    /*  display: flex;*/
    /*  flex-direction: column;*/
    /*  flex: 50%;*/
    /*  margin: 10px 30px;*/
    /*  box-sizing: border-box;*/
    /*}*/
    padding: 4px;
    text-wrap: normal;

    /*textarea {*/
    /*  width: 100%;*/
    /*  height: 450px;*/
    /*  resize: none;*/
    /*  background-color: #cfc;*/
    /*}*/

    /*button {*/
    /*  display: block;*/
    /*  width: 200px;*/
    /*  margin: 10px auto;*/
    /*  border: 5px solid #390;*/
    /*  padding: 10px 5px;*/
    /*  border-radius: 5px;*/
    /*  background-color: #af6;*/
    /*}*/
    font-size: 8px;
    color: grey;
    /*overflow-wrap: break-word;*/

    /*button:hover {*/
    /*  cursor: pointer;*/
}

/*  background-color: #9e5;*/
/*  border-color: #4a1;*/
/*}*/
.handConverterCloser {
    margin-right: 4px;
    margin-left: 4px;
    font-size: 15px;
}

/*h2 {*/
/*  text-align: center;*/
/*  color: #030;*/
/*  font-family: 'Arial', sans-serif;*/
/*  text-transform: uppercase;*/
/*}*/
.copyClipboard {
    display: flex;
    color: grey;
    font-size: 14px;
    /*text-transform: uppercase;*/
    padding: 3px;
    margin-right: 20px;
    cursor: pointer;
    align-content: center;
}

.convertionsLeft {
    display: flex;
    color: grey;
    font-size: 12px;
    text-transform: uppercase;
    padding: 3px;
    cursor: pointer;
    align-content: center;

    /*select {*/
    /*  display: block;*/
    /*  width: 200px;*/
    /*  margin: 10px auto;*/
    /*  font-size: 18px;*/
    /*  border: 5px solid #390;*/
    /*  border-radius: 5px;*/
    /*  padding: 3px;*/
    /*  background-color: #cfc;*/
    /*}*/
}

.convertLoading {
    margin-top: -8px;
}
.convertConfirmation {
}

.limitReached {
    display: flex;
    color: grey;
    font-size: 12px;
    padding: 6px;
}
