@font-face {
    font-family: 'Readex Pro';
    font-weight: 100 1000;
    src: url('assets/fonts/ReadexPro-VariableFont_HEXP,wght.ttf') format('truetype-variations');
}

body {
    font-family: 'Readex Pro', sans-serif;
    font-style: normal;
    /*font-family: "Readex Pro", sans-serif;*/
    /*overflow-y: scroll;*/
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

label {
    /* display: block; */
    margin-top: 10px;
}

.mobile-text {
    font-size: 18px !important;
    overflow-wrap: break-word;
    display: block;
}

@media (max-width: 991.98px) {
    .mobile-text {
        font-size: 10px !important;
        display: block;
    }
}
.container-nav {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1300px;
    margin: 0px auto;
    box-sizing: border-box;
}
.ButtonPlate {
    margin-left: 10px;
    width: 71px;
    height: 32px;
    border-radius: 10px;
    min-width: 71px;
    font-family: 'Readex Pro', sans-serif;
    /* border: solid 1px #058373; */
    /* background-image: "./static/images/dice.png"; */
    /* background: rgba(35, 47, 52, 0) url("static/images/dice.png"); */
    background-repeat: no-repeat;
    background-position: 12px 5px;
    outline: none;
}

.ButtonPlate p {
    width: 17px;
    height: 16px;
    font-family: 'Readex Pro', sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    /* color: #388080; */
    text-align: center;
    padding-left: 32px;
    padding-top: 6px;
    margin-right: auto;
}

.ButtonPlate p:hover {
    color: white;
}

.ButtonPlate:hover {
    background: rgba(35, 47, 52, 0) url('static/images/dice_white.png');
    background-color: #388080;
    background-repeat: no-repeat;
    background-position: 12px 5px;
    outline: none;
}

.ButtonPlate:hover > p {
    color: white;
}

.ButtonPlate:active {
    transform: scale(1.05);
}

.ButtonPlate:focus {
    outline: none;
}

.navbar-brand {
    /* color: #388080 !important; */
    color: #3c7070 !important;

    /* color: linear-gradient(to bottom, #06beb6 0%, #48b1bf 100%); */
    /* background: -webkit-linear-gradient(#06beb6, #388080); */
}

/*.content {*/
/*    !* background-color: rgba(240, 240, 240, 0.9); *!*/
/*    border-radius: 8px;*/
/*    !* padding: 30px; *!*/

/*    !* max-width: 1800px; *!*/
/*    !* margin-left: 10px;*/
/*    margin-right: 10px; *!*/

/*    display: grid;*/

/*    grid-gap: 0%;*/
/*    grid-auto-rows: minmax(500, auto);*/

/*    grid-template-columns: 1fr 50%;*/
/*    max-width: 1550px;*/
/*}*/
.content-charts {
    /* background-color: rgba(240, 240, 240, 0.9); */
    border-radius: 8px;
    /* padding: 30px; */

    /* max-width: 1800px; */
    /* margin-left: 10px;
    margin-right: 10px; */

    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 24px;
    justify-content: center;
    max-width: 1440px;
}

@media (max-width: 1506px) {

    .content-charts {
        width: calc(100% - 64px) !important;
    }
}

@media (max-width: 900px) {

    .content-charts {
        width: calc(100% - 20px) !important;
    }

    .tileInside {
        font-size: 10px !important;
    }
}

@media (min-width: 1600px) {
    .content-charts {
        max-width: 1920px;
        width: calc(100% - 64px) !important;
    }
}

.content-charts > div:first-child {
    grid-column-start: 1;
    grid-column-end: 8;
}

.content-charts > div:last-child {
    grid-column-start: 8;
    grid-column-end: 13;
}

.content-charts .matrixContainer {
    max-width: unset;
}

@media (max-width: 767px) {
    .content-charts {
        display: block;
    }
}
.contentRegular {
    display: flex;

    justify-content: center;

    /* margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
      max-width: 1700px; */
}

.contentPricing {
    margin-top: 15px;
    /* background-color: rgba(240, 240, 240, 0.9); */
    border-radius: 8px;
    padding: 30px;

    /* max-width: 1900px; */
    /*margin-left: 10px;*/
    /*margin-right: 10px;*/
}

.charts {
    width: 100%;

    grid-column: 1;
    grid-row: 1;

    /* overflow: hidden; */
}

.chartsInside {
    /* max-width: 800px; */

    width: 100%;
    /*padding: 0px 10px 20px 20px;*/

    float: right;
}

.charts p {
    font-family: 'Readex Pro', sans-serif;
    font-size: 15px;
    margin-left: 0px;
    margin-bottom: 5px;

    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.gui {
    width: 100%;

    grid-column: 2;
    grid-row: 1;
    /* height: 1px;
  margin-left: 5%; */

    border-left: 1px solid
        linear-gradient(180deg, rgba(255, 255, 255, 0.2) 100%, rgba(255, 255, 255, 0) 100%);
}

@media (max-width: 767px) {
    .gui {
        padding: 0px;
    }
}

.guiInside {
    /* max-width: 900px; */

    /*background-color: rgb(37, 40, 44, 0.35);*/
    background: #202328;
    border-radius: 10px;
    padding: 16px;
}

.gui p {
    /*font-family: "Readex Pro", sans-serif;*/
    font-size: 11px;

    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.12px;
    /* color: #4b4a4a; */
    text-transform: uppercase;
}

.gui button {
    min-width: 18%;
    width: auto;
    height: 32px;
    border-radius: 50px;
    background-color: #f2f3f8;

    font-size: 15px;
    /*font-weight: normal;*/
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(67, 66, 66, 0.87);
    border: solid 0px #388080;
    margin-right: 10px;
    margin-bottom: 8px;
    outline: none;
    /*overflow: hidden;*/
    /* text-overflow: ellipsis; */
    white-space: nowrap;
}

.charts button {
    width: auto;

    height: 32px;
    border-radius: 50px;
    background-color: #f2f3f8;

    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(67, 66, 66, 0.87);
    border: solid 0px #388080;
    margin-right: 10px;
    margin-top: 8px;
    outline: none;
}

.guiButtonsSection {
    position: relative;
    width: 100%;
    display: flex;
    /*flex-direction: row;*/
    flex-flow: row wrap;
    /*justify-content: space-between;*/
    /*align-content: flex-start;*/
    gap: 8px;
}

.guiDropdowns {
    width: 240px;
}

.guiButtonsScenario {
    position: relative;
    width: 100%;
    display: flex;
    /*flex-direction: row;*/
    flex-flow: row wrap;
    /*justify-content: space-between;*/
    /*align-content: flex-start;*/
    gap: 8px;
}

.gui button:active {
}

/* .gui button:hover:enabled {
  width: 100px;
  height: 32px;
  border-radius: 50px;
  background-color: #388080;
  font-family: WorkSansMedium;
  font-size: 14px;

  color: rgba(255, 255, 255, 0.87);
  border: solid 0px #388080;
  outline: none;
} */

.gui button p {
}

.gui button:disabled,
button[disabled],
button:hover:disabled {
    opacity: 0.25;
}

.gui .buttonScenario {
    width: 173px;
}

.gui .buttonScenario:hover:enabled {
    width: 173px;
}

.gui .buttonPosition {
    min-width: 9%;
    width: 9%;
}

.guiButtonsXMax {
    min-width: 15%;
    width: auto;

    height: 32px;
    border-radius: 50px;
    background-color: #f2f3f8;

    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(67, 66, 66, 0.87);
    border: solid 0px #388080;
    margin-right: 10px;
    margin-bottom: 8px;
    outline: none;
    overflow: hidden;
    /* text-overflow: ellipsis; */
    white-space: nowrap;
}

.gui .buttonShort {
    /* width: 9%; */
}

.buttonSimplify {
    width: auto !important;
}

.gui .buttonFlatter {
    min-width: 9%;
    width: 9%;
    /* width: 63px; */
}

.flatter {
    /* background-color: #950952 !important; */
    border: 1px solid rgb(149, 9, 82, 0.75) !important;
    /* width: 100px !important; */
}

.gui .buttonFlatter:hover:enabled {
    background-color: #950952 !important;
}

.gui .buttonFlatter:enabled {
    /* background-color: #950952 !important; */
}

.gui .buttonPosition:hover:enabled {
    min-width: 9%;
    width: 9%;
}

.gui .buttonOpponent {
    min-width: 9%;
    width: 9%;
}

.gui .buttonOpponent:hover:enabled {
    min-width: 9%;
    width: 9%;
}

.headerChipICM {
    padding-bottom: 30px;
}

.headerSectionFirst p {
    padding-bottom: 2px;

    margin: 16px 0px 8px 0px;
}

.headerSectionHeroStackSize p {
    padding-bottom: 2px;
    margin: 24px 0px 8px 0px;
}

.bg-light {
    background-color: #f1f2f7 !important;
}

.feather {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
}

/* Header */
header {
    top: 0px;
    left: 0;
    width: 100%;
}

.smoke-x {
    font-size: 15px;
    z-index: 510;
    color: white;
    text-align: center;
    line-height: 1;
    /* font-weight: bold; */
    vertical-align: center;
    top: 150px;
    left: 50px;
    width: 20px;
    padding: 20px;
    height: 20px;
    border-radius: 50%;
    position: fixed;

    background-color: #950952;
}

.smoke {
    font-size: 15px;
    z-index: 500;
    color: white;
    text-align: center;
    line-height: 1;
    /* font-weight: bold; */
    vertical-align: center;
    top: 150px;
    left: 30px;
    width: 200px;
    padding: 20px;
    height: 160px;
    border-radius: 50%;
    position: fixed;

    background-color: #950952;
}

.message-x {
    font-size: 15px;
    z-index: 510;
    color: white;
    text-align: center;
    line-height: 1;
    /* font-weight: bold; */
    vertical-align: center;
    top: 80px;
    left: 290px;
    width: 20px;
    padding: 20px;
    height: 20px;
    border-radius: 50%;
    position: fixed;

    background-color: #950952;
}

.message {
    font-size: 15px;
    z-index: 500;
    color: white;
    text-align: center;
    line-height: 1;
    /* font-weight: bold; */
    vertical-align: center;
    top: 80px;
    left: 30px;
    width: 300px;
    padding: 20px;
    height: 60px;
    position: fixed;

    background-color: #950952;
}

@media (max-width: 1199.98px) {
    .message,
    .message-x {
        display: none !important;
    }

    .smoke {
        /* display: none !important; */
    }

    header {
        top: 0;
    }
}

header .navbar-brand {
    text-transform: uppercase;
    letter-spacing: 0.2em;
    font-weight: 400;
}

header .navbar-brand.absolute {
    position: absolute;
}

@media (max-width: 991.98px) {
    header .navbar-brand.absolute {
        position: relative;
    }
}

header .navbar-brand span {
    color: #388080;
}

header .navbar {
    padding-top: 0;
    padding-bottom: 0;

    position: relative;
    background-color: #161616 !important;
    font-family: 'Readex Pro', sans-serif;
}

@media (max-width: 991.98px) {
    header .navbar {
        /* background: black !important; */
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
}

header .navbar .nav-link {
    /*padding: 1.3rem 1rem;*/
    outline: none !important;

    cursor: pointer;
    font-family: 'Readex Pro', sans-serif;
    /*font-family: "Readex Pro", sans-serif;*/
    font-style: normal;

    font-size: 16px;
    line-height: 28px;
    color: #979ead;
}

/*@media (max-width: 1199.98px) {*/
/*    header .navbar .nav-link {*/
/*        padding: 1.0rem 1rem;*/
/*    }*/

/*    .home-text-2 {*/

/*        width: 80%;*/
/*    }*/
/*}*/

header .navbar .nav-link.active {
    color: #fff;
}

.home-text-2 {
    font-size: 18px;
    width: 60%;
}

header .navbar .dropdown-menu {
    font-size: 13px;
    border-radius: 4px;
    border: none;
    -webkit-box-shadow: 0 2px 30px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 30px 0px rgba(0, 0, 0, 0.2);
    min-width: 13em;
    margin-top: -10px;
}

header .navbar .dropdown-menu:before {
    bottom: 100%;
    left: 10%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #fff;
    border-width: 7px;
}

@media (max-width: 1199.98px) {
    .dropdown {
        margin-top: 5px !important;
        padding-top: 0px !important;
    }
}

@media (max-width: 991.98px) {
    header .navbar .dropdown-menu:before {
        display: none;
    }
}

header .navbar .dropdown-menu .dropdown-item:hover {
    background: #71774b;
    color: #fff;
}

header .navbar .dropdown-menu .dropdown-item.active {
    background: #71774b;
    color: #fff;
}

header .navbar .dropdown-menu a {
    padding-top: 7px;
    padding-bottom: 7px;
}

header .navbar .cta-btn a {
    background: #71774b;
    color: #000 !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    text-transform: uppercase;
    padding-top: 26px !important;
    padding-bottom: 26px !important;
    line-height: 1;
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease;
}

header .navbar .cta-btn a:hover {
    color: #fff !important;
    -webkit-box-shadow: 2px 0 30px -5px rgba(113, 119, 75, 0.9);
    box-shadow: 2px 0 30px -5px rgba(113, 119, 75, 0.9);
}

header .search-form {
    position: relative;
}

header .search-form .icon {
    position: absolute;
    top: 50%;
    font-size: 1.2rem;
    left: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

header .search-form .form-control {
    padding-left: 30px;
    border: none;
    height: 44px;
    border-radius: 0;
    background: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    color: #fff;
}

.slider-item {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: calc(100vh);
    min-height: 700px;
    position: relative;
}

.slider-item .slider-text {
    color: #fff;
    height: calc(100vh);
    min-height: 700px;
    /* margin-top: -50px; */
}

.slider-item .slider-text .child-name {
    font-size: 40px;
    color: #fff;
}

.slider-item .slider-text h1 {
    font-size: 50px;
    color: #fff;
    line-height: 0.8;
    font-weight: 300 !important;
    text-transform: uppercase;
    margin-bottom: 30px;
    margin-left: 0.5em;
}

.slider-item .slider-text h1 span {
    color: white;

    /* background-color: #388080; */
}

@media (max-width: 991.98px) {
    .slider-item .slider-text h1 span {
        background-color: #3c7070;
        -webkit-box-shadow: 0.5em 0 0 #3c7070 ', -0.5em 0 0 #388080';
        box-shadow:
            0.5em 0 0 #3c7070,
            -0.5em 0 0 #3c7070;
    }
}

@media (max-width: 991.98px) {
    .slider-item .slider-text h1 {
        font-size: 40px;
    }
}

.slider-item .slider-text p {
    font-size: 20px;
    line-height: 1.5;
    font-weight: 300;
    color: white;
    margin: 0 auto;
}

.slider-item .slider-text p a {
    color: #71774b;
    text-decoration: underline;
}

.slider-item .slider-text p a:hover {
    color: #fff;
    text-decoration: underline;
}

.slider-item .slider-text p.sub-text {
    line-height: 2;
}

.loader-container {
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 50px;
}

.loader-container div {
    margin: 0 auto;
    display: block;
}

.navbar-dark .navbar-nav .nav-link {
    color: #979ead;
}

.site-footer {
    font-size: 11px;
    margin-top: 350px;
    padding: 7em 0;
    border-top: #eee;
}

.site-footer p:last-child {
    margin-bottom: 0;
}

.site-footer a {
    color: #388080;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.site-footer a:hover {
    color: #000;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.site-footer h3 {
    font-size: 12px;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: bold;
}

.site-footer .footer-link li {
    line-height: 1.5;
    margin-bottom: 15px;
}

.site-footer hr {
    width: 100%;
}

.footer-social a {
    line-height: 0;
    border-radius: 50%;
    margin: 0 5px 5px 0;
    border: 1px solid rgba(255, 255, 255, 0.1);
    width: 30px;
    height: 30px;
    text-align: center;
    display: inline-block;
}

.footer-social a:hover {
    background: #fff;
    border-color: #fff;
    color: #000;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.fakeHeader {
    height: 50px;
    /*background: red;*/
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;

    margin-right: auto;
    margin-left: auto;
}

.btn-aio-color {
    /* background-color: #388080; */
    background-color: #3c7070 !important;
    color: white;
}

.btn-aio {
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /*-webkit-user-select: none;*/
    user-select: none;

    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 10px;
    transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}

.btn-aio-color:hover {
    background-color: #f2f3f8 !important;
    color: #388080;
    border: 1px solid #388080;
}

.btn-aio-w-100 {
    width: 100%;
}

.trans {
    transition: 2s;
}

.dropdown {
    z-index: 1000 !important;
    position: absolute;
    width: auto;
    /*margin-top: 10px;*/
    padding-left: 1px !important;

    box-shadow: 0 0px 0 rgba(0, 0, 0, 0);
    /* margin-right: 50px; */
    /* color: #5e5e5e; */

    /* border: 1px solid #bbbdc0 !important; */
}

.dropdown .nav-item .nav-link {
    /* height: 30px !important;
    font-size: 10px !important;

    padding-top: 10px !important;
    padding-left: 6px;
    padding-right: 5px; */
}

.nav-item {
    transition: width 2s;
}

.dropdown li {
    list-style-type: none;
}

.regi-login {
    /*display: inline-block;*/

    /*margin: 0px auto;*/
}

.registration,
.login {
    margin: 0px auto;

    margin-top: 35px;

    border-radius: 8px;
    padding: 30px;

    max-width: 600px;
}

@media only screen and (max-width: 2560px), (min-device-width: 680px) {
    .login,
    .registration {
        width: 40%;
    }
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

.languages {
    margin-left: 15px;
    width: 200px;
    display: flex;
}

.lan {
    padding: 5px;
    width: 50px;
}

.hero {
    display: inline-flex;

    padding-left: 8px;
    padding-right: 8px;

    text-align: center;
    align-items: center;
    justify-content: center;

    float: left;
    min-width: 40px;
    height: 22px;
    margin: 0 0 0 12px;

    border-radius: 8px;
    border: solid 1px rgba(255, 255, 255, 0.2);

    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;

    letter-spacing: 1.5px;
    text-align: left;
    color: rgba(255, 255, 255, 0.8);
    text-transform: uppercase;
}

.idmatrix {
    float: left;
    margin-left: 5px;
    margin-bottom: 5px;
    display: inline-flex;

    object-fit: contain;

    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: grey;
    text-align: center;
    /*align-items: center;*/
    /*justify-content: center;*/
    margin-top: 4px;

    /*max-width: 50%;*/
    /*text-wrap: normal;*/
    /*word-break: break-word;*/
    /*overflow-wrap: anywhere;*/
}

.raiseSizeing {
    color: rgba(245, 245, 245, 0.5);
    font-size: 13px;
    float: right;

    margin-left: 4px;
    padding: 4px;
    white-space: pre-wrap;
    display: flex;
    font-weight: 400;
    line-height: 13px;
}

.opponent {
}

/* .invisible {
opacity: 0.0;
} */

.resetButton button {
    width: auto !important;

    float: right;
}

.strategyButton button {
    margin-top: 65px;

    float: left;
}

.strategyButton {
    font-size: 14px;
    margin-top: 40px;
    /*display: flex;*/
    /*justify-content: space-between;*/
}

.againstTos {
    display: inline-block;
}

.btnPeriod {
    /* width: 100px !important; */

    border-radius: 10px !important;

    font-size: 18px;

    outline: none;

    text-align: center;
    padding: 3px;
    margin-right: 10px;

    width: 25%;

    height: 38px;
    border-radius: 50px;
    background-color: #f2f3f8;

    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(67, 66, 66, 0.87);
    border: solid 0px #388080;
    margin-right: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.btnPeriod:focus {
    outline: none;
}

.stripe {
    text-align: center;
    margin-top: 20px;
    font-size: 12px;
}

.btn-subscribe {
}

.buttonSimulation {
    width: 100% !important;
    height: 20px !important;

    margin-bottom: 3px !important;
    /* padding: 1px !important;; */
    /* margin: 3px !important;; */
}

.guiButtonSimulationSection {
    width: 100%;
    display: inline-flex;

    /* align-items: center; */
    justify-content: center;
}

.icmStacks {
    /* background-color: black; */
    /*text-align: center;*/
    /*justify-content: center;*/
    display: inline-flex;
    padding: 0px 2px;
}

.bigStack {
    color: rgb(9, 92, 9, 0.7) !important;
    border-radius: 10px !important;
}

.smallestStack {
    color: rgb(139, 11, 11, 0.7) !important;
    border-radius: 10px !important;
}

.guiSims {
    width: 50%;
}

.simHeader {
    font-size: 12px;
    margin-top: 12px;
    margin-bottom: 2px;
    display: flex;
    /*justify-content: space-between;*/
    /*padding: 1px 6px;*/
    text-align: left;
}

h1 .simulations-header {
    font-style: normal;
    font-weight: 800;
    font-size: 39px;
    line-height: 48px;
    margin: 24px;
    text-align: center;

    letter-spacing: -1px;
    background: linear-gradient(270deg, #459393 0%, #ffc7a8 100%),
        linear-gradient(0deg, #ffffff, #ffffff);

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.avg {
    cursor: pointer;
}

.guiSimsTable {
    width: 50%;
    height: 400px;
    margin-top: 20px;
    /* background-color: red; */
    background-position: center !important; /* Center the image */
    background-repeat: no-repeat !important; /* Do not repeat the image */
    background-size: contain !important;
    background-position: center;
}

.imageTableICM {
    background-position: center !important; /* Center the image */
    background-repeat: no-repeat !important; /* Do not repeat the image */
    background-size: contain !important;
    background-position: center;
}

.containerBirthdate {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    height: 95vh;
}

.childBirthdate {
    font-size: 14px;

    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: white;
}

.inputBirthday {
    width: 150px;
    margin-right: 8px;
}

.buttonBirthday {
    width: 90px;
    height: 24px;
    border-radius: 5px;
    background-color: #f2f3f8;

    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(67, 66, 66, 0.87);
    border: solid 0px #388080;

    outline: none;
    overflow: hidden;
    /* text-overflow: ellipsis; */
    white-space: nowrap;
}

.tiles-item {
    max-width: fit-content !important;
}

#tsparticles {
    height: 100%;
}

.particles {
    opacity: 0.5;
    position: fixed;
    width: 100%;
    left: 0;
    height: 100%;
    z-index: -10;
}

.video-layer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    height: 120vh;

    z-index: -11;
}

.tutorial {
    width: 50%;
    margin: 5px;
    border: 2px solid #388080;
}

.tutorial-container {
    display: flex;
    margin: 3px;
    width: 100%;
}

.video-container {
    z-index: -100;

    align-content: center;
    justify-content: center;
    /* opacity: 0.9; */
}

.homeContainer {
    display: flex;
}

.homeText {
    width: 30%;
    text-align: justify;
}

.homeVideo {
    width: 70%;
}

.video {
    /* -webkit-box-shadow: -1px 0px 15px 9px rgba(77,77,77,1);
    -moz-box-shadow: -1px 0px 15px 9px rgba(77,77,77,1);
    box-shadow: -1px 0px 15px 9px rgba(77,77,77,1); */

    width: 100%;
    height: 100%;
    background: none;
    margin: 0px auto;

    /* height: 100vh;
    width: 100vh; */
}

.features-tiles-item-image {
    width: 64px;
    height: 64px;
    align-items: center;
    justify-content: center;
    font-size: 25px;
}

.bg-green {
    /* background-color: #388080; */
    background-color: #3c7070;
    padding: 3px;
    color: #f4f4f4;
    margin: 5px;
}

.product-wrapper-first {
    border-radius: 16px;
    /* background: rgba(121, 121, 121, 0.1);		 */
    /*border: 2px solid rgba(121, 121, 121, 0.1);*/
    background: #202328;
    margin: 10px;
    max-width: 222px;

    /*height: fit-content;*/
}

.product-wrapper-details {
    border-radius: 16px;
    /* background: rgba(121, 121, 121, 0.1);		 */
    /*border: 2px solid rgba(121, 121, 121, 0.1);*/
    background: #202328;
    margin: 10px;
    max-width: 222px;
    /*height: fit-content;*/
}

.product-wrapper-first:after {
    margin-top: -30px;
    height: 400px;
    position: absolute;
    margin-left: 28px;

    width: 100px;
    z-index: -1;
    content: '';
    padding: 100px;

    border-left: 1px dashed #202328;

    padding: 2px;
}

.btn-buy {
    background-color: rgb(149, 9, 82) !important;
    color: white !important;
    width: 100% !important;
    font-family: 'Readex Pro';
    font-size: 14px;
}

.btn-buy:hover {
    color: black !important;
}

.btn-buy a:hover {
    color: black !important;
}

.most-popular::before {
    content: 'MOST POPULAR';
    padding: 8px;
    float: left;

    margin-right: 50px;
    font-weight: bold;

    background-color: #388080;
    border-radius: 16px 16px 0px 0px;
    /*font-family: Readex Pro;*/
    font-style: normal;
    font-weight: 800;
    font-size: 9px;
    line-height: 9px;
    /* identical to box height, or 100% */

    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    width: 100%;
}

.most-popular {
    background-color: #2a2e35;
}
.discount-color {
    background: #950952;
    padding: 5px;
}

.pricing-togglers-container {
    text-align: center;
    margin-top: 32px;
}

.pricing-togglers-container-header {
    font-weight: 600;
    font-size: 21px;
    line-height: 24px;
}

.pricing-toggler-monthly {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    text-align: center;
    letter-spacing: -0.5px;
    margin-right: 25px;
    color: #636978;
}

.pricing-toggler-yearly {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    text-align: center;
    letter-spacing: -0.5px;
    margin: 10px;
    color: #636978;
}
.pricing-toggler-active {
    color: #ffffff;
}

.pricing-divider {
    margin-top: 80px;
    margin-bottom: 70px;
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 56px;
    /* identical to box height, or 117% */
    text-align: center;

    letter-spacing: -1px;
    background: linear-gradient(270deg, #459393 0%, #ffc7a8 100%),
        linear-gradient(0deg, #ffffff, #ffffff);

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /*border: 15px solid #161616;*/
}

.pricing-toggler-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    margin-bottom: 50px;
}

.ticks-list-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.pricing-header-container {
    display: block;
    justify-content: center;
    align-items: center;
    /*max-width: 650px;*/
    margin: 10px;
}
.pricing-subscriptions {
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: 20px;
    color: #388080;
}
.pricing-simple-pricing-plans {
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 56px;
    /* identical to box height, or 117% */
    margin: 20px;
    text-align: center;

    letter-spacing: -1px;
    background: linear-gradient(270deg, #459393 0%, #ffc7a8 100%),
        linear-gradient(0deg, #ffffff, #ffffff);

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.pricing-header-description {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    /* or 156% */
    margin: 20px;
    text-align: center;

    color: #ffffff;
}
.product-header {
    height: 200px;
}
.product-header-second {
    height: 80px;
    display: flex;
    align-items: flex-end;
}
.align-items-flex-end {
    display: flex;
    align-items: flex-end;
}
.product-header-content-container {
    display: flex;
}

.product-header-content {
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 48px;
    /* identical to box height, or 120% */

    letter-spacing: -1px;

    color: #ffffff;
    align-self: flex-end;
    white-space: normal;
}
.product-header-item {
    height: 56px;
    text-align: center;
    font-size: 14px;
    line-height: 57px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.product-item-font {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 143% */

    text-align: center;

    color: #ffffff;
}
.product-categories {
    height: 80px;
    line-height: 20px;
    font-size: 12px;

    /*border: 3px solid green;*/
}

.product-header-categories {
    height: 80px;
}
.product-header-item .tooltiptext {
    visibility: hidden;
    color: black;
    text-align: center;
    padding: 5px;
    padding-left: 8px;
    padding-right: 8px;
    line-height: 20px;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    margin-top: 80px;
    margin-left: 3px;
    background: #fafafa;
    border-radius: 8px;
    height: auto;
    transition-delay: 0.3s;
    font-weight: normal;
    font-size: 12px;
    max-width: 230px;
    white-space: pre-wrap;
}
.pointer {
    cursor: default;
}

.dashed {
    text-decoration-style: dashed;
    text-decoration-line: underline;

    text-decoration-color: white;
}
.product-header-item:hover .tooltiptext {
    visibility: visible;
    transition-delay: 0.4s;
}

.product-price-save {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;

    /* identical to box height, or 112% */

    background: linear-gradient(270deg, #459393 0%, #ffc7a8 100%),
        linear-gradient(0deg, #ffffff, #ffffff);

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /*border: 15px solid #161616;*/

    text-align: center;
    margin: 4px;
    /*color: #388080; */
}
.product-header-item-font-header {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    white-space: nowrap;
}
.left {
    text-align: left;
    justify-content: left;
}

.product-descriptions {
    margin: 10px;
    width: 300px;

    margin-right: 30px;
}

.product-cta {
    padding: 10px 16px;

    background: #388080;
    border-radius: 64px;
    border: 0px;
    margin: 15px;
    white-space: nowrap;
    background: #fa6060;
}

.product-cta-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.product-cta p {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    text-align: center;

    color: #ffffff;

    /* Inside Auto Layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
}
.product-name {
    padding: 25px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    /* identical to box height, or 156% */

    text-align: center;
}

.product-price {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    /* identical to box height, or 87% */

    text-align: center;
}

.color-white {
    color: white;
}

.product-lined {
    /*color: #8c0d0d;*/
    font-size: 20px;
}
.product-price-net {
    font-size: 10px;
    line-height: 24px;
    color: #888d99;
    font-style: normal;
    font-weight: normal;
}
.product-price-per-day {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 18px;
    /* or 150% */

    text-align: center;

    color: #888d99;

    text-align: center;
}

.product-price-per {
    margin-top: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    /* or 150% */
    text-align: center;
    color: #888d99;
}

.mtt-wrapper::before {
    content: 'MTT';
    font-weight: bold;
    padding: 7px;
    float: right;
    margin: 0px;
    margin-top: -25px;
    margin-right: 15px;
    font-size: 13px;

    background-color: #3c7070;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.spins-wrapper::before {
    content: 'SPIN&GO';
    font-weight: bold;
    padding: 7px;
    float: right;
    margin-top: -25px;
    margin-right: 15px;
    font-size: 13px;
    color: black;

    background-color: rgb(250, 221, 148);

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.spins-wrapper {
    margin-left: 130px;
}

.product {
    padding: 20px;
    /* max-width: 50% !important;  */
    /* width: 500px !important; */
    /* width: 360px !important; */
    /* width: 100% !important; */
}

.productPrice {
    /* max-width: 50% !important;  */
    /* width: 500px !important; */
    height: 50px !important;
}

.productData {
    /* max-width: 50% !important;  */
    /* width: 500px !important; */
    height: 100px !important;
}

.productBilled {
    /* max-width: 50% !important;  */
    /* width: 500px !important; */
    height: 100px !important;
}

.productStacks {
    /* max-width: 50% !important;  */
    /* width: 500px !important; */
    height: 155px !important;
}

.cardbox {
    margin-top: 30px !important;
    width: 66% !important;
    /*background-color: #25282C;*/
    border: 2px solid rgba(121, 121, 121, 0.1);
    border-radius: 10px;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 32px;
    padding-right: 32px;
    max-width: 600px;
}

.cardboxw100 {
    /*width: 100%;*/
    /*background-color: #25282C;*/
    background: rgba(37, 40, 44, 0.35);
    border-radius: 10px;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 32px;
    padding-right: 32px;
}

.color-f4 {
    color: #f4f4f4 !important;
}

/*
.ElementsApp {
  color: black !important
} */

.ElementsApp,
.ElementsApp .InputElement {
    color: white !important;
}

.contentTerms {
    margin-top: 75px;

    border-radius: 8px;
    padding: 30px;

    max-width: 1400px;
    margin-left: 10px;
    margin-right: 10px;
}

.contentTerms a:hover {
    color: inherit;
    opacity: 0.7;
    text-decoration: none;
}

.nav-link-com {
    color: black;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
}

.checkbox {
    align-items: center;
    margin-right: 5px;
}

.checkbox:checked {
    background-color: #388080;
}

.reg-pricing {
    margin: 0 auto;
    display: block;
    justify-content: center !important;
    align-items: center !important;
}

.preline {
    white-space: pre-line;
}

.seepricing {
    border-radius: 10px;

    font-family: 'Readex Pro';
    min-width: 15%;
    width: 250px;
    height: 50px;

    font-size: 25px;

    font-weight: bold;
    text-transform: uppercase;
    border: solid 0px #388080;

    outline: none;
    overflow: hidden;
    /* text-overflow: ellipsis; */
    white-space: nowrap;

    background-color: #3c7070;
    color: #f4f4f4;
}

.seepricing:hover {
    background-color: white !important;
    color: #3c7070;
    outline: none;
    border: solid 1px #388080;
}

.seepricing:focus {
    outline: none;
}

.promo {
    /* background-color: #950952 ; */
    background-color: #fadd94;
    color: black !important;
}

.table-grid {
    padding-left: 10px;
    padding-right: 10px;
    height: 100%;
    /* background-color: rgba(240, 240, 240, 0.9); */
    border-radius: 8px;

    font-size: 13px;
    width: 100%;
    display: grid;

    grid-gap: 7%;

    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /*grid-template-rows: repeat(auto-fill, 120px);*/
    /*grid-row-gap: .5em;*/
    /*grid-column-gap: 1em;*/
    /* grid-auto-rows: minmax(100, auto); */
    /*
      grid-template-columns: 1fr fit-content(50%);
      grid-template-rows: 1fr fit-content(33%); */
}

.table-grid-inside {
    /*background-repeat: no-repeat !important;*/
    /*background-size: 26% !important;;*/

    /*background-position: -15% 150% !important;*/

    background-image: repeating-linear-gradient(
        -45deg,
        rgba(255, 255, 255, 0.05),
        rgba(255, 255, 255, 0.05) 1px,
        transparent 1px,
        transparent 6px
    );

    /* overflow: hidden; */
}

.table-player {
    border-radius: 10px;
    width: 100px;
    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-items: center;

    color: white;
    height: 30%;

    background-color: rgba(0, 0, 0, 0.6);
}

.table-player-bubble {
    /*margin-top: 10px !important;*/
    font-size: 12px;
    padding: 2px;
    border-radius: 10px;

    /*margin-left: 3px;*/
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    color: white;
}

.height-auto {
    height: auto;
}

.table-player-bubble-right {
    float: right;
}

.evICMrow-in {
    /*width: 70px;*/
}

.evICMrow {
}

.highlight {
    border-radius: 5px;
    border: 1px solid grey;
    padding: 5px;
    font-size: 12px;
}

.evaluatedICM-inner-payouts {
}

.evaluatedICM-inner-right {
}
.evaluatedICM {
    padding-top: 10px;

    font-size: 11px;

    display: flex;
    text-align: center;
    justify-content: space-around;

    color: white;

    width: 100%;
}

.bubbleFactorHeader {
    width: 50%;
    color: #1a6c93;
}
.bubbleFactorContainer {
    overflow: hidden;
}

.bountyContainer {
}

.bubbleFactor {
    width: 50%;
}

.table-grid-inside2 {
    /*
       display: flex;
      justify-content: center;
      align-items: center; */
    background-repeat: no-repeat !important;
    background-size: 16% !important;

    background-position: 5% 82% !important;
    grid-column: 2;
    grid-row: 1;
    /* overflow: hidden; */
}

.table-grid-inside3 {
    grid-column: 1;
    grid-row: 2;
    /* overflow: hidden; */
}

.table-grid-inside4 {
    background-repeat: no-repeat !important;
    background-size: 16% !important;

    background-position: 15% 0% !important;
    grid-column: 2;
    grid-row: 2;
    /* overflow: hidden; */
}

.table-grid-inside5 {
    grid-column: 1;
    grid-row: 3;
    /* overflow: hidden; */
}

.table-grid-inside6 {
    grid-column: 2;
    grid-row: 3;
    /* overflow: hidden; */
}

.color-be {
    color: #bebebe !important;
}

.color-bes {
    color: #bebebe !important;
    font-size: 10px;
}

.brand-ico {
    font-size: 12px;
}

.textland {
    font-size: 25px;
}

.arrow {
    font-size: 50px !important;
    --animate-duration: 1800ms;
    --animate-delay: 0.9s;
}

.przerwa {
    display: inline-flex;
    width: 13px !important;
}

.arrow:hover {
    color: white;
}

.split-item-image {
    /* border: 10px solid rgba(255,255,255,0.1); */
    border: 10px solid rgba(0, 0, 0, 0.2);
}

.navbar-toggler {
    display: none;
}

.just-cont {
    justify-content: center;
}

.no-mobile-version {
    display: none;
}

@media only screen and (max-width: 991px) {
    .mobile {
        display: block !important;
    }

    .homeContainer {
        display: block;
    }

    .homeText {
        width: 100%;
    }

    .homeVideo {
        display: none;
    }

    .promoHeader {
        margin-top: 15px;
    }

    header {
        z-index: 555;
        /*position: fixed;*/
    }

    h2 {
        font-size: 15px !important;
    }

    /* .color-f4 {
      font-size: 25px;
    } */
    .no-mobile-version {
        display: block;
    }

    .show {
        display: block;
    }

    .navbar-toggler {
        display: block;
        /*margin-right: 20px;*/
    }

    .navbar-toggler:focus {
        outline: none;
    }

    .collapse {
        margin-top: 15px;
    }

    #root {
        /*overflow-x: hidden;*/
    }

    .navbar-nav {
    }

    .navbar {
        position: fixed !important;
        width: 100%;
    }

    header .navbar {
        background-color: #161616 !important;
        z-index: -11;
    }

    .navbar-collapse {
        text-align: center;
    }

    .nav-item {
        width: 100%;

        font-size: 20px;
        margin-right: 20px;

        padding: 10px;
    }

    .nav-item-drop {
    }

    .nav-link {
        height: 50px;
    }

    .nav-item-land-mobile {
    }

    .dropdown {
        position: relative;
    }

    .rng {
        display: none;
    }

    .toggler {
        display: none;
    }

    .mobile-break {
        height: 30px;
    }

    .login,
    .registration {
        width: 100%;
    }

    .contentPricing {
        display: block !important;
        padding: 0px;
    }

    .textland {
        font-size: 20px !important;
    }

    .container {
        display: block !important;
        padding: 0px;
    }

    .reg-pricing {
        margin: 0px !important;
    }

    .font-size-mobile-xl {
        font-size: 23px;
    }

    .font-size-mobile-l {
        font-size: 10px;
    }

    .registration {
        padding: 0px !important;
    }

    .contentPricing {
        padding: 0px !important;
        width: 100%;
    }

    .content {
        padding: 0px !important;
        width: 100%;
        display: block;
    }

    .slider-item .slider-text {
        margin-top: 0px;
    }

    .video-container {
        display: none;
    }

    .video-layer {
        display: none;
    }

    .text-xl {
        font-size: 13px !important;
    }

    .text-2xl {
        font-size: 15px !important;
    }

    .text-base {
        font-size: 12px !important;
    }

    .cardbox {
        margin: 0px auto !important;
        margin-top: 35px !important;
        align-items: center !important;
        width: 100% !important;
    }

    .rounded-md {
    }

    /* Force table to not be like tables anymore */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
        /*display: block;*/
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    tr {
        border: 1px solid #ccc;
    }

    td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
    }

    td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
    }
}

a {
    color: white;
}

.line-through {
    text-decoration: line-through;
}

.panka {
    top: -50px;
    left: -20px;

    width: 100px;
    height: 100px;
    border-image: solid 1px #388080;
    /* border: solid 1px #388080; */
    /* background-image: "./static/images/dice.png"; */
    background: rgba(35, 47, 52, 0) url('static/images/panka2.png');
    background-position: center !important; /* Center the image */
    background-repeat: no-repeat !important; /* Do not repeat the image */
    background-size: contain !important;
    background-position: center;
}

.apestyles {
    top: -50px;
    left: -20px;

    width: 100px;
    height: 100px;
    border-image: solid 1px #388080;
    /* border: solid 1px #388080; */
    /* background-image: "./static/images/dice.png"; */
    background: rgba(35, 47, 52, 0) url('static/images/apestyles.png');
    background-position: center !important; /* Center the image */
    background-repeat: no-repeat !important; /* Do not repeat the image */
    background-size: contain !important;
    background-position: center;
}

.brzeski {
    top: -50px;
    left: -20px;

    width: 100px;
    height: 100px;
    border-image: solid 1px #388080;
    /* border: solid 1px #388080; */
    /* background-image: "./static/images/dice.png"; */
    background: rgba(35, 47, 52, 0) url('static/images/brzeski.png');
    background-position: center !important; /* Center the image */
    background-repeat: no-repeat !important; /* Do not repeat the image */
    background-size: contain !important;
    background-position: center;
}

.millenial {
    /*width: 100px;*/
    height: 100px;
    border-image: solid 1px #388080;
    /* border: solid 1px #388080; */
    /* background-image: "./static/images/dice.png"; */
    background: rgba(35, 47, 52, 0) url('static/images/millenial.png');
    background-position: center !important; /* Center the image */
    background-repeat: no-repeat !important; /* Do not repeat the image */
    background-size: contain !important;
    background-position: center;
}

.millenial-recordings {
    width: 100px;
    height: 100px;
    border-image: solid 1px #388080;
    /* border: solid 1px #388080; */
    /* background-image: "./static/images/dice.png"; */
    background: rgba(35, 47, 52, 0) url('static/images/millenial.png');
    background-repeat: no-repeat !important; /* Do not repeat the image */
    background-size: contain !important;
}

.testimonial-item-footer {
    text-align: center;
}

.teges {
    width: 100px;
    height: 100px;
    border-image: solid 1px #388080;
    /* border: solid 1px #388080; */
    /* background-image: "./static/images/dice.png"; */
    background: rgba(35, 47, 52, 0) url('static/images/teges.png');
    background-position: center !important; /* Center the image */
    background-repeat: no-repeat !important; /* Do not repeat the image */
    background-size: contain !important;
    background-position: center;
}

.olor {
    width: 100px;
    height: 100px;
    border-image: solid 1px #388080;
    /* border: solid 1px #388080; */
    /* background-image: "./static/images/dice.png"; */
    background: rgba(35, 47, 52, 0) url('static/images/michalak.png');
    background-position: center !important; /* Center the image */
    background-repeat: no-repeat !important; /* Do not repeat the image */
    background-size: contain !important;
    background-position: center;
}

.terms {
    text-align: justify;
}

.promoHeader {
    z-index: -1;

    text-align: center;

    background-color: #fadd94;
    border-radius: 10px;

    color: black;

    height: 78px;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    /*border-top: 4px solid black;*/

    width: 100%;
}

@media only screen and (max-width: 991px) {
    .product-wrapper {
        margin-left: 0px;
    }

    .spins-wrapper {
        margin-left: 0px;
    }

    .promoHeader {
        /*top: 60px;*/
        font-size: 15px;
        height: auto;
        padding: 5px;
    }

    .tutorial-container {
        display: block;
    }

    .tutorial {
        width: 100%;
    }
}

.pricing {
    padding: 7px;
    border: 1px solid #fadd94;
    background-color: #fadd94;
    border-radius: 10px;
}

.pricing:hover {
    background-color: #161616;
}

.logopa {
    width: 140px;
    padding-top: 8px;
    margin-left: 10px;
    margin-top: 5px;
}
.logopa-mobile {
    width: 100px;
    margin-left: 10px;
    margin-top: 5px;
}
.logopa-mobile-czapka {
    width: 30px;
}
.app2 {
    margin-top: 140px;
}

.account {
    max-width: 1300px;
    margin-top: 40px;
    margin-bottom: 40px;
    width: 100%;
}

@media only screen and (max-width: 991px) {
    .logopa {
        width: 260px;
    }

    .app2 {
        margin-top: 20px;
    }

    .account {
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 1280px) {
    /* CSS */
    .contentRegular {
        /* margin-top: 5px; */
    }

    .account {
    }

    .content {
        padding: 2px;
    }

    .gui button {
        height: 35px;
        margin-right: 5px;
    }

    .gui .buttonPosition {
        min-width: 10%;
        /*width: 9%;*/
        padding: 0px;
        text-align: center;
        justify-content: center;
    }
    .gui .buttonPosition:hover:enabled {
        min-width: 10%;
        /*width: 9%;*/
        padding: 0px;
        text-align: center;
        justify-content: center;
    }

    .gui .buttonOpponent:hover:enabled {
        min-width: 10%;
        /*width: 9%;*/
        padding: 0px;
        text-align: center;
        justify-content: center;
    }
    .gui .buttonFlatter:hover:enabled {
        min-width: 10%;
        /*width: 9%;*/
        padding: 0px;
        text-align: center;
        justify-content: center;
    }

    .gui .buttonOpponent {
        min-width: 10%;
        /*width: 9%;*/
        padding: 0px;
        text-align: center;
        justify-content: center;
    }
    .gui .buttonFlatter {
        min-width: 10%;
        /*width: 9%;*/
        padding: 0px;
        text-align: center;
        justify-content: center;
    }

    .tile {
        /* height: 18px !important; */
    }

    .label {
        margin-right: 10px !important;
    }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

.color-green {
    color: #388080;
}

.nav-line {
    width: 100%;
    height: 1px;
    margin-left: 5%;
    background: linear-gradient(242deg, rgba(204, 204, 204, 0.2) 0%, rgba(198, 198, 198, 0) 90%);
}

.divider {
    line-height: 30px;
    width: 1px;
    margin: 15px 15px 0px 15px;
    height: 35px;
    background-color: rgba(255, 255, 255, 0.1);
}

.button-active {
    background-color: #3c7070 !important;
    color: #e7eeee !important;
}

.main {
}

.controlPanelBackground {
    width: 100%;
}

@media (max-width: 993px) {
    .contentRegular {
        /*margin-top: 50px;*/
    }
}

.messageLine {
    /*background: #950952;*/
    width: 100%;

    background: linear-gradient(242deg, rgba(149, 9, 82, 0.5) 0%, rgba(198, 198, 198, 0) 60%);
    font-size: 14px;
    padding: 4px;
    text-align: right;
    color: #a5a5a5;
    padding-right: 10px;
}
.checkout-quit {
    /*background: #950952;*/

    width: 100%;
    font-size: 14px;
    padding: 10px;
    padding-right: 15px;
    text-align: right;
    /*color: #a5a5a5;*/
}

.modal-quit {
    /*background: #950952;*/
    position: absolute;
    width: 100%;
    font-size: 14px;
    padding: 5px;
    padding-right: 15px;
    text-align: right;
    /*color: #a5a5a5;*/
}
.gohome {
    width: 100%;
    height: 200vh;
    position: absolute;
    /*background: black;*/
    /*opacity: 0.8;*/
    color: white;
    font-size: 40px;
    z-index: 100000;
    background-color: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(5px);
}
.tax-text {
    font-size: 15px;
    font-weight: normal;
}
.total-due {
    font-size: 20px !important;
}
.checkout-modal-container {
    color: white;
    font-size: 40px;
    z-index: 11;
    position: relative;
}

.blanket {
    width: 100%;
    height: 300%;
    position: absolute;
    z-index: 5555;
    background-color: rgba(0, 0, 0, 0.6);
    /*backdrop-filter: blur(4px);*/
}
.checkout-modal {
    z-index: 15;
    border-radius: 16px;
    border: 1px solid grey;
}

.checkout-page {
    padding: 20px;
}

.paymentRequired {
    margin-top: 20px;
}

.lostPassword {
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: grey !important;
    margin-top: 30px;
    text-decoration: none;
}

.lostPassword a:hover {
    color: white;
    text-decoration: none;
}

.soon:after {
    position: absolute;
    content: 'soon';
    font-weight: bold;
    padding: 3px;
    /*float: right;*/
    margin-left: 15px;

    /*margin-right: ;*/
    /*margin-right: -15px;*/
    /*margin-top: 100px;*/
    /*margin-right: 15px;*/
    font-size: 10px;

    background-color: #ffc8a8;
    color: black;
    border-radius: 5px;
    opacity: 1;
    /*border-top-left-radius: 10px;*/
    /*border-top-right-radius: 10px;*/
}

.newRangeViewerSwitcher {
}
.newRangeViewer {
    margin-right: 40px;
}
/*.newRangeViewer:after{*/
/*    content: 'beta';*/
/*    font-weight: bold;*/
/*    padding: 3px;*/
/*    float: right;*/
/*    margin: 3px;*/
/* margin-top: 10px;*/
/*    margin-right: -23px;*/
/*    !*margin-top: -25px;*!*/
/*    !*margin-right: 15px;*!*/
/*    font-size: 10px;*/

/*    background-color: #cf0303;*/
/*    color: white;*/
/*    border-radius: 5px;*/
/*    opacity: 0.9;*/
/*    !*border-top-left-radius: 10px;*!*/
/*    !*border-top-right-radius: 10px;*!*/
/*}*/
.heads-up:before {
    content: 'beta';
    font-weight: bold;
    padding: 3px;
    float: right;
    margin: 3px;
    margin-right: -5px;
    margin-right: -23px;
    /*margin-top: -25px;*/
    /*margin-right: 15px;*/
    font-size: 10px;

    background-color: #cf0303;
    color: white;
    border-radius: 5px;
    opacity: 0.9;
    /*border-top-left-radius: 10px;*/
    /*border-top-right-radius: 10px;*/
}

@media (max-width: 653px) {
    .guiInside {
        /* max-width: 900px; */
        padding: 10px;
    }

    .chartsInside {
        /*padding: 10px;*/
        font-size: 12px;
    }

    .title-container {
        display: block !important;
    }

    .notEv {
        width: 100% !important;
    }

    .title {
        width: 100%;
    }

    .guiButtonsSection {
        justify-content: space-between !important;
    }

    .gui button {
        min-width: 17%;
        height: 36px;
        padding: 4px;
    }

    .evContainer {
        /*margin-top: 3px  !important;*/
        margin-bottom: 16px !important;
    }

    .contentRegular {
        /*margin-top: 30px;*/
    }

    header .navbar:after {
        /*background: linear-gradient(*/
        /*        242deg*/
        /*        , rgba(204,204,204,0.1) 0%, rgba(198,198,198,0) 90%);*/
        content: '';
        position: absolute;

        display: inline-block;
        /*transform: rotate(45deg);*/
        margin-top: 58px;
        height: 1px;
        width: 102%;
        left: -0.5rem;
        /*border-bottom: 1px solid #3c7070;*/
        /*border-right: 2px solid #3c7070;*/
    }

    .navbar {
        padding: 10px;
    }
}

.navbar-content {
    align-items: center;
    justify-items: center;

    text-align: center;
}

.matrixImageContainer img {
    align-items: center;
    justify-items: center;
    text-align: center;
    /*background: red;*/
}
.matrixImageContainer img {
    object-fit: scale-down;
    width: 100%;
}
.matrixImage {
}

.remember {
    padding: 10px 10px;
    font-weight: bold;

    max-width: 500px;

    border: 1px solid #388080;
    box-sizing: border-box;
    border-radius: 64px;
    color: white !important;
}

@media (max-width: 767px) {
    .product-wrapper-first {
        max-width: none;
        margin: 0px;
        margin-bottom: 20px;
    }

    .product-name {
        padding: 10px;
    }

    .product-header-item {
        height: 15px;
        line-height: 15px;
        font-weight: bold;
    }
    .mobileInfo {
        width: 200px;
        text-align: left;
        justify-content: left;
        margin-left: 55px;
    }
}

.chartsEmptyState {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menuTourNavMob {
    font-size: 13px;
}

.bottomX {
    content: '';

    height: 50px;
    position: absolute;
    padding-left: 20px;

    margin-right: 20px;
    bottom: 100px;
}

.paymentRequiredModal {
    padding: 20px;
}

.customerPortalLink {
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
}

/*@media (max-width: 1580.98px) {*/

/*.cards-container {*/

/*    margin-top: 13px;*/
/*}}*/
.captcha {
    display: flex;
    justify-content: space-between;
}

.upgrade {
    width: 350px;
}

.link-primary {
    text-decoration: underline;
}

.link-primary:hover {
    color: #388080;
    text-decoration: underline;
}

.new-pricing-toggler-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 4px 8px;
    background: #292c33;
    border-radius: 20px;
    margin: 0 auto;
    margin-top: 16px;
    max-width: 400px;
    gap: 8px;
}

.pricing-toggler-option {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    /* identical to box height, or 150% */
    text-align: center;
    letter-spacing: -0.5px;
    color: #55afaf;
    min-width: 110px;
    padding: 8px 0;
    box-sizing: border-box;
    cursor: pointer;
}

.new-pricing-toggler-active {
    color: #ffffff;
    background: #388080;
    border-radius: 16px;
}

h2:has(.new-pricing-simple-pricing-plans) {
    background-color: inherit;
    margin-bottom: 8px;
    margin-left: 0;
    padding: 0;
}

h2 .new-pricing-simple-pricing-plans {
    font-style: normal;
    font-weight: 600;
    font-size: 39px;
    line-height: 48px;
    margin: 24px;
    text-align: center;

    letter-spacing: -1px;
    background: linear-gradient(270deg, #459393 0%, #ffc7a8 100%),
        linear-gradient(0deg, #ffffff, #ffffff);

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

h2 .new-pricing-simple-pricing-plans:first-child {
    margin-bottom: 0;
}

h2 .new-pricing-simple-pricing-plans:last-child {
    margin-top: 0;
}

.ticks-list-element {
    font-size: 16px;
    line-height: 24px;
    padding: 0 16px;
    display: flex;
    gap: 16px;
    align-items: center;
}

.new-pricing-simple-pricing-benefits > div {
    transform: translateY(2px);
}

.pricing-togglers-savings {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #ffc7a8;
    margin: 0 auto;
    margin-top: 8px;
    max-width: 276px;
    text-align: center;
    background: #292c33;
    padding: 5px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    gap: 8px;
}

.pricing-togglers-savings img {
    width: 16px;
    height: 16px;
}

.pricing-main-section {
    display: flex;
    justify-content: center;
    margin-top: 64px;
    gap: 24px;
    margin-bottom: 16px;
    flex: 4;
}

.pricing-options-list {
    max-width: 276px;
    width: 276px;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
}

.pricing-options-list-affiliate {
    padding-top: 118px;
}

.pricing-options-list-main {
    font-weight: 600;
    font-size: 21px;
    line-height: 24px;
    margin-bottom: 8px;
    margin-top: 16px;
    display: flex;
    gap: 16px;
}

.pricing-options-new-category {
    padding: 0px 8px;
    height: 24px;
    background: rgba(255, 199, 168, 0.1);
    border-radius: 8px;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.1em;
    font-variant: all-small-caps;
    color: #ffc7a8;
}

.pricing-options-list-sub {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
    display: flex;
    gap: 8px;
}

.pricing-options-panel {
    max-width: 276px;
    width: 276px;
    background: #202328;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    padding-bottom: 16px;
}

.pricing-options-panel > div.loader {
    align-self: center;
}

.pricing-options-panel-affiliate {
    max-width: 360px;
    width: 360px;
}

.pricing-options-panel-affiliate > div:first-child {
    border-radius: 16px 16px 0 0;
}

.pricing-options-most-popular {
    color: black;
    background: #ffc7a8;
    font-size: 16px;
    line-height: 24px;
    padding: 8px 0;
    font-weight: 600;
    width: 100%;
    border-radius: 16px 16px 0 0;
    text-align: center;
}

.pricing-options-most-popular:empty {
    display: none;
    height: 40px;
    padding: 0;
    background: inherit;
}

.pricing-options-standard,
.pricing-options-essential,
.pricing-options-cash,
.pricing-options-spins {
    margin-top: 40px;
}

.pricing-options-title {
    margin-top: 16px;
    font-size: 28px;
    line-height: 32px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    color: #ffffff;
}

@media (min-width: 993px) {
    .pricing-options-title-affiliate {
        margin-bottom: 64px;
    }
}

.pricing-options-title-affiliate > div {
    margin-left: 0 !important;
}

.pricing-options-title > img {
    margin-right: -12px;
}

.pricing-options-title > div {
    margin-left: 28px;
}

.pricing-options-description {
    padding: 0 16px;
    font-style: italic;
    font-size: 16px;
    line-height: 24px;
    height: 48px;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 44px;
    color: rgba(255, 255, 255, 0.5);
}

.pricing-features-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 32px;
    margin-bottom: 48px;
}

.pricing-features-main-affiliate {
    margin: 0 16px 32px 16px;
}

.pricing-features-main:first-child {
    margin-bottom: 0;
}

.pricing-features-main:last-child {
    border-bottom: none;
}

.pricing-features-detailed {
    text-align: center;
    margin: 0;
    padding: 0;
    margin-top: 14px;
    margin-bottom: 2px;
    height: 16px;
}

.pricing-features-detailed img {
    display: block;
    width: 16px;
    height: 16px;
    margin: 0 auto;
    padding: 0;
}

.pricing-features-detailed-with-text {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.pricing-features-detailed-with-text .info span {
    text-align: center;
}

.pricing-features-detailed-with-text div {
    color: rgba(255, 255, 255, 0.85);
}

.pricing-features-detailed-with-text > div.best-option {
    color: #ffc7a8;
}

.pricing-features-detailed-with-text img {
    display: inline-block;
    margin: 0;
}

.pricing-options-price {
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    justify-content: center;
    gap: 24px;
    align-items: center;
    position: relative;
}

.pricing-options-price span {
    text-align: center;
}

.pricing-options-price.right-alignment {
    justify-content: flex-start;
    gap: 16px;
}

.pricing-options-price.right-alignment > span {
    transform: translateX(-50%);
}

.pricing-options-price.right-alignment > span:first-child {
    margin-left: 50%;
}

.pricing-options-price-monthly {
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-transform: lowercase;
    color: rgba(255, 255, 255, 0.5);
}

.pricing-options-select-button {
    padding: 9px 24px 9px 24px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    background: #388080;
    border-radius: 16px;
    border: none;
    margin: 0 auto;
    margin-top: 16px;
    width: calc(100% - 32px);
    box-sizing: border-box;
}

.pricing-options-select-button:hover {
    color: inherit;
    text-decoration: none;
}

.pricing-options-select-button:hover {
    box-shadow:
        0px 4px 16px rgba(56, 128, 128, 0.28),
        0px 2px 8px rgba(56, 128, 128, 0.16),
        0px 0px 2px rgba(56, 128, 128, 0.12);
}

.pricing-options-select-button:focus,
.pricing-options-select-button:focus-visible {
    outline: 0;
    box-shadow:
        0px 0px 0px 2px #202328,
        0px 0px 0px 3px #388080,
        0px 4px 16px rgba(56, 128, 128, 0.28),
        0px 2px 8px rgba(56, 128, 128, 0.16),
        0px 0px 2px rgba(56, 128, 128, 0.12);
}

.pricing-options-select-button:disabled {
    opacity: 0.25;
}

.pricing-options-select-button.best-option {
    background: #ffc7a8;
    color: #000000;
}

.pricing-options-select-button.best-option:hover {
    box-shadow:
        0px 4px 16px rgba(250, 96, 96, 0.28),
        0px 2px 8px rgba(250, 96, 96, 0.16),
        0px 0px 2px rgba(250, 96, 96, 0.12);
}

.pricing-options-select-button.best-option:focus,
.pricing-options-select-button.best-option:focus-visible {
    outline: 0;
    box-shadow:
        0px 0px 0px 2px #202328,
        0px 0px 0px 3px #fa6060,
        0px 4px 16px rgba(250, 96, 96, 0.28),
        0px 2px 8px rgba(250, 96, 96, 0.16),
        0px 0px 2px rgba(250, 96, 96, 0.12);
}

.pricing-price-description {
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.01em;
    color: rgba(255, 255, 255, 0.5);
    margin-top: 8px;
}

.pricing-price-savings {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 8px;
    margin-top: -8px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.pricing-price-savings > div {
    height: 24px;
}

.pricing-price-savings:empty {
    margin: 0;
}

.pricing-options-old-price {
    text-decoration-line: line-through;
    text-decoration-thickness: from-font;
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    line-height: 24px;
}

.pricing-options-monthly {
    /*position: absolute;*/
    right: 15px;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-transform: lowercase;
    color: rgba(255, 255, 255, 0.5);
    margin-left: 8px;
}

.pricing-feature-detailed-label {
    display: none;
}

.pricing-features-main-mobile {
    display: none;
}

.pricing-trial-version,
.pricing-contact-us,
.pricing-spin-go {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
    gap: 16px;
    background: #202328;
    position: relative;
    left: 0;
}

.pricing-trial-version {
    margin-left: calc(-1 * (-10px + 0.07 * min(100vw, 1440px)));
    width: calc(100vw - 16px);
}

.pricing-contact-us {
    width: 776px;
    margin: 0 auto;
    margin-top: 80px;
    background: inherit;
    border: 1px solid #353b45;
    border-radius: 16px;
}

.pricing-contact-us.full-width {
    margin-left: 0;
    margin-right: 0;
}

.pricing-spin-go {
    width: 776px;
    margin: 0 auto;
    margin-top: 24px;
    background: inherit;
    border: 1px solid #ffc7a8;
    border-radius: 16px;
}

.pricing-trial-version-header,
.pricing-contact-us-header,
.pricing-spin-go-header {
    font-weight: 600;
    font-size: 21px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    margin-top: 0;
}

.pricing-trial-version-description,
.pricing-contact-us-description,
.pricing-spin-go-description {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
}

.pricing-spin-go-description .h2 {
    display: block;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}

.pricing-trial-version button,
.pricing-contact-us button,
.pricing-spin-go-select-button {
    padding: 7px 24px 9px 24px;
    border: 2px solid #388080;
    border-radius: 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    background: inherit;
}

.pricing-trial-version button:hover {
    box-shadow:
        0px 4px 16px rgba(56, 128, 128, 0.28),
        0px 2px 8px rgba(56, 128, 128, 0.16),
        0px 0px 2px rgba(56, 128, 128, 0.12);
}

.pricing-trial-version button:focus,
.pricing-trial-version button:focus-visible {
    box-shadow:
        0px 0px 0px 2px #202328,
        0px 0px 0px 3px #388080,
        0px 4px 16px rgba(56, 128, 128, 0.28),
        0px 2px 8px rgba(56, 128, 128, 0.16),
        0px 0px 2px rgba(56, 128, 128, 0.12);
    outline: 0;
}

.pricing-spin-go-select-button {
    background: #ffc7a8;
    color: #000000;
    border: none;
    text-decoration: none;
}

.pricing-spin-go-select-button:hover {
    box-shadow:
        0px 4px 16px rgba(255, 199, 168, 0.28),
        0px 2px 8px rgba(255, 199, 168, 0.16),
        0px 0px 2px rgba(255, 199, 168, 0.12);
    color: #000000;
    text-decoration: none;
}

.pricing-spin-go-select-button:focus,
.pricing-spin-go-select-button:focus-visible {
    box-shadow:
        0px 0px 0px 2px #202328,
        0px 0px 0px 3px #ffc7a8,
        0px 4px 16px rgba(255, 199, 168, 0.28),
        0px 2px 8px rgba(255, 199, 168, 0.16),
        0px 0px 2px rgba(255, 199, 168, 0.12);
    outline: 0;
    color: #000000;
    text-decoration: none;
}

.pricing-contact-us button {
    border: 1px solid #353b45;
    color: #55afaf;
}

.pricing-contact-us button:hover,
.pricing-contact-us button:focus,
.pricing-contact-us button:focus-visible {
    background: #292c33;
    border: 1px solid #353b45;
}

.pricing-contact-us button:focus,
.pricing-contact-us button:focus-visible {
    box-shadow:
        0px 0px 0px 2px #202328,
        0px 0px 0px 3px #388080,
        0px 4px 16px rgba(56, 128, 128, 0.28),
        0px 2px 8px rgba(56, 128, 128, 0.16),
        0px 0px 2px rgba(56, 128, 128, 0.12);
    outline: 0;
}

.pricing-faq-header {
    background: linear-gradient(270deg, #388080 0%, #ffc7a8 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 600;
    font-size: 38px;
    line-height: 40px;
    text-align: center;
    letter-spacing: -0.01em;
    margin-top: 44px;
    margin-bottom: 32px;
}

.pricing-faq-main {
    width: 776px;
    margin: 0 auto;
}

.pricing-faq-main > div > div {
    margin-right: 0 !important;
}

.pricing-spin-go-plan-type {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #ffc7a8;
    margin: 0 auto;
    margin-top: -24px;
    text-align: center;
    text-transform: uppercase;
}

@media ((max-width: 1138px) and (min-width: 992px)) or ((max-width: 908px) and (min-width: 840px)) or (max-width: 300px) {
    .pricing-options-new-category span:last-child {
        display: none;
    }
}

@media (max-width: 992px) {
    .pricing-trial-version {
        margin-left: 0;
        width: 100%;
    }

    .pricing-options-most-popular:empty {
        display: block;
    }

    .pricing-options-list {
        display: none;
    }

    .pricing-features-detailed {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }

    .pricing-features-detailed img {
        margin-right: 0;
        margin-left: auto;
    }

    .pricing-feature-detailed-label {
        display: block;
    }

    .pricing-features-detailed-with-text div.info {
        display: none;
    }

    .ticks-list-container {
        flex-direction: column;
        gap: 8px;
        width: auto;
    }

    .fit-content-align-start {
        width: fit-content;
        align-items: start;
        margin: 0 auto;
    }

    .new-pricing-simple-pricing-plans {
        margin: 0 16px;
    }

    .new-pricing-simple-pricing-plans:first-child {
        margin-top: 16px;
    }

    .new-pricing-simple-pricing-plans:last-child {
        margin-bottom: 16px;
    }

    .pricing-options-standard,
    .pricing-options-essential {
        margin-top: 0;
    }

    .pricing-main-section {
        margin-top: 60px;
        padding: 0 32px;
    }

    .pricing-features-main-mobile {
        display: block;
    }

    .pricing-options-description {
        margin-bottom: 0;
    }

    .pricing-features-main {
        margin: 8px 16px;
    }

    .pricing-options-select-button {
        margin-top: 8px;
    }

    .pricing-price-savings,
    .pricing-price-savings:empty {
        min-height: 24px;
        margin-top: 8px;
        margin-bottom: 8px;
    }
}

@media (max-width: 1200px) {
    .new-pricing-simple-pricing-plans {
        margin-top: 0;
    }
}

@media (min-width: 840px) {
    .pricing-options-price-monthly {
        margin-left: -10%;
        transform: translateX(-10%) !important;
    }
}

@media (max-width: 850px) {
    .pricing-options-most-popular:empty {
        display: none;
    }

    .pricing-spin-go {
        padding: 16px;
        min-width: 520px;
        width: 520px;
        margin-left: auto;
        margin-right: auto;
    }

    .pricing-main-section {
        flex-direction: column;
        gap: 24px;
        align-items: center;
    }

    .pricing-main-section {
        margin-top: 24px;
    }

    .pricing-options-panel {
        min-width: 520px;
        width: 520px;
    }

    .pricing-price-savings:empty {
        height: auto;
        padding: 0;
        margin: 0;
    }

    .pricing-options-description {
        height: auto;
    }

    .pricing-features-mobile-hide {
        display: none;
    }

    .pricing-trial-version,
    .pricing-contact-us,
    .pricing-faq-main {
        width: calc(100% - 32px);
        margin-left: 16px;
        margin-right: 16px;
        border-radius: 16px;
    }

    .pricing-features-detailed-with-text div.info:not(.best-option) {
        display: block;
    }
}

@media (max-width: 552px) {
    .pricing-main-section {
        padding-left: 16px;
        padding-right: 16px;
    }

    .pricing-options-panel {
        min-width: 100%;
        width: 100%;
        box-sizing: border-box;
    }

    .pricing-spin-go {
        min-width: calc(100% - 32px);
        width: calc(100% - 32px);
        margin-left: 16px;
        margin-right: 16px;
        box-sizing: border-box;
    }
}

.right-nav-buttons {
    display: flex;
    gap: 8px;
    align-items: center;
}

.features-tab-asset {
    width: 776px;
    z-index: 2;
}

.features-tab-header {
    color: #ffffff;
    font-size: 21px;
    line-height: 24px;
    margin-top: 16px;
}

.features-tabs-section {
    width: 1184px;
    margin-top: 16px;
}

.features-tabs-section > div:not(.ellipse) {
    display: none;
}

.features-tabs-section > div > div {
    align-items: flex-start;
}

.features-tabs-section > div.active {
    display: block;
}

.features-tabs-section ul {
    list-style-type: disc !important;
    padding-inline-start: 16px !important;
    color: rgba(255, 255, 255, 0.5);
}

.features-tabs-section ul ul {
    padding-inline-start: 24px !important;
}

.features-main-header {
    background: linear-gradient(270deg, #388080 0%, #ffc7a8 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-align: center;
    letter-spacing: -0.01em;
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 40px;
    width: 800px;
    margin-bottom: 24px !important;
}

.features-tabs-nav {
    display: flex;
    color: rgba(255, 255, 255, 0.5);
}

.features-tabs-nav > div {
    border-bottom: 2px solid #353b45;
    padding: 8px 16px;
    cursor: pointer;
}

.features-tabs-nav .active {
    color: #ffffff;
    border-color: #388080;
}

.features-tabs-section .ellipse {
    position: absolute;
    width: 270px;
    height: 270px;
    background: #388080;
    opacity: 0.35;
    filter: blur(125px);
    z-index: 1;
}

.features-tabs-section .ellipse:first-child {
    margin-top: 250px;
    margin-left: 300px;
}

.features-tabs-section .ellipse:nth-child(2) {
    margin-top: -82px;
    margin-left: 1080px;
}

.main-feature-tab {
    border: 1px solid #202328;
    filter: drop-shadow(0px 12px 16px rgba(0, 0, 0, 0.16))
        drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.121481))
        drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06));
    border-radius: 32px;
    box-sizing: border-box;
}

.main-feature-tab-asset img {
    width: 100%;
    height: auto;
}

.main-feature-tab-asset {
    max-width: 100%;
    border-radius: 32px 32px 0 0;
    display: flex;
    justify-content: center;
    overflow: hidden;
    min-height: 170px;
}

.main-feature-tabs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 32px;
    max-width: 872px;
}

.main-feature-tab-header {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-top: 16px;
    padding: 0px 32px;
}

.main-feature-tab-header > div:first-child {
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    color: #ffffff;
}

.main-feature-tab-description {
    color: rgba(255, 255, 255, 0.5);
    padding: 0px 32px;
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 24px;
}

.styled-inner-html span {
    font-weight: 600;
    color: #ffffff;
}

@media (max-width: 981px) {
    .main-feature-tab-header > div:first-child {
        font-size: 21px;
        line-height: 24px;
    }
}

@media (max-width: 1150px) {
    .main-feature-tab-header > div:first-child {
        font-size: 21px;
        line-height: 24px;
    }

    .main-feature-tab-header {
        padding: 0 16px;
    }

    .main-feature-tab-header > div:nth-child(2) {
        margin-right: -8px;
    }

    .main-feature-tab-description {
        padding: 0 16px;
    }

    .main-feature-tab-header .pricing-options-new-category {
        padding: 0 4px;
    }
}

@media (max-width: 984px) {
    .main-feature-tabs {
        display: flex;
        flex-direction: column;
    }
}

.with-links a {
    color: #ffffff;
}

.with-links a:hover {
    opacity: 0.7;
    text-decoration: none;
}

@media ((max-width: 1193px) and (min-width: 993px)) {
    .pricing-options-list-sub.two-liner {
        height: 58px;
    }

    .pricing-options-panel-affiliate .pricing-features-detailed.two-liner {
        height: 48px;
    }
}
