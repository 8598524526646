.randomizer {
    cursor: pointer;
}
.randomizer-value {
    text-align: center;
}

.randomizer-main {
    border-radius: 10px;
    background: #222;
    margin-top: 10px;
    padding: 10px;
}

.randomizer-value {
    color: white;
    font-size: 58px;
    font-weight: 500;
    line-height: 68px;
    font-family: sans-serif;
}

.randomizer button {
    color: #9fa0ad;
    border: none;
    font-family: 'Readex Pro', sans-serif;
    font-weight: 600;
    margin: 0;
    padding: 15px;
}
