.webinarCheckout {
    width: 50%;
}

.webinarProUsers {
    justify-content: center;
    align-items: center;
    text-align: center;

    padding: 20px;
}

.container-recordings {
    width: 100%;
    display: flex;
    /*background-color: rgba(37,40,44,.35);*/
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.main-content {
    width: 100%;
    max-width: 1300px;
}

.recording-info-container {
    width: 50%;

    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*text-align: center;*/
}

@media (max-width: 991.98px) {
    .container-recordings {
        display: block;
        width: 100%;
    }
    .recording-info-container {
        width: 100%;
    }
    .webinarCheckout {
        width: 100%;
    }
}

.video-container-recordings {
    height: 300px;
    border: 1px solid #388080;
    border-radius: 5px;
    display: flex;

    justify-content: center;
    align-items: center;
    text-align: center;
}

.video-recordings {
    /* -webkit-box-shadow: -1px 0px 15px 9px rgba(77,77,77,1);
    -moz-box-shadow: -1px 0px 15px 9px rgba(77,77,77,1);
    box-shadow: -1px 0px 15px 9px rgba(77,77,77,1); */

    width: 100%;
    height: 100%;
    background: none;
    margin: 0px auto;

    /* height: 100vh;
    width: 100vh; */
}

.recording-info {
    width: 100%;
    display: flex;
}

.recording-date {
    font-size: 11px;
    opacity: 0.8;
}

.recording-title {
    /*margin-left: 30px;*/
    font-size: 20px;
    font-weight: bold;
}

.recording-coach-container {
    display: flex;
}

.recording-coach {
    display: flex;

    margin-left: 16px;
    width: 100%;
    justify-content: left;
    align-items: center;
    text-align: left;
}

.recording-coach-name {
    /*font-weight: 700;*/
    font-size: 14px;
    opacity: 0.8;
    font-weight: 300;
}

.onlyPro {
}
