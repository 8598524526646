/* react-multi-email styles */
.react-multi-email {
    display: flex;
    flex-direction: row;
    color: white;
}

.react-multi-email input {
    width: 100%;
    background-color: transparent;
    border-color: #667793;
    border-width: 2px;
    border-radius: 16px;
    height: 40px;
    font-weight: 400;
    color: rgb(255, 255, 255, 0.85);
    padding-left: 8px;
    padding-right: 8px;
    font-size: 14px;

    &:hover {
        background: #292c33;
        box-shadow:
            0px 0px 2px 0px #3880801f,
            0px 2px 8px 0px #38808029,
            0px 4px 16px 0px #38808047;
        border-color: #388080;
    }

    &:focus {
        border: 0px;
    }
}

.react-multi-email focused empty {
    border: 0px;
}

.react-multi-email .email-tag {
    /*background-color: black;*/
    /*color: white;*/
}

.react-multi-email .email-tag span {
    color: white;
}

.react-multi-email .email-tag .remove {
    color: white;
}

.react-multi-email input::placeholder {
    color: white;
}
