.odds-section {
    margin-top: 20px;
}

.odds-section-container {
    display: flex;
    cursor: pointer;

    padding: 2px 5px;
}
.odds-section-icon {
    line-height: 30px;
    font-size: 14px;
    width: 20px;
}

.odds-section-little-header {
    display: flex;
    justify-content: space-between;
    padding-right: 30px;
    line-height: 30px;
    width: 100%;
}
.odds-section table {
    width: 100%;
    color: #ccc;
    font-weight: 400;
    cursor: pointer;
    height: 40px;
    overflow: hidden;
}

.odds-main-container {
    border: 1px solid rgb(89, 89, 89);
    font-size: 14px;
    border-radius: 8px;
    margin-bottom: 10px;
}

.odds-section table thead {
    text-transform: uppercase;
    /*background: #3A3A3A;*/
    display: flex;
    text-align: justify;
    cursor: pointer;
    font-size: 14px;
    /*    border-radius: 8px;*/
    /*border: 1px solid rgb(89, 89, 89);*/
    /*    padding: 4px;*/
    /*    z-index: 9999;*/

    background: #16181c;
}

.odds-section table thead:hover {
    opacity: 0.8;
}

.odds-section table thead th {
    /*flex: 1;*/
    /*border: 1px solid white;*/
    /*padding: 5px;*/
    height: 30px;
    line-height: 30px;
    /*  display: flex;*/
    /*  justify-content: space-around;*/
    /*  align-content: center;*/
    /*!*font-family: Oxygen, sans-serif;*!*/
    /*letter-spacing: -0.02em;*/
}

.odds-section table thead th span {
    position: relative;
    left: -16.25px;
}

.odds-section table tbody.collapsed {
    display: none;
}

.odds-section table tr td {
    padding-left: 10%;
}

.odds-section table div tr td {
    flex: 1;
    padding: 2px 20px;
}

.odds-section table tr:nth-child(odd) {
    background: #222;
    /*background: rgba(255,255,255, 0.1);*/
}

.odds-section table tr td:nth-child(2) {
    text-align: center;
}

.odds-section .icon {
    float: left;
    color: #ccc;
}
