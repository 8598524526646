.webinarContainer {
}

.webinarInfo {
    width: 50%;

    display: flex;
    justify-content: center;
    /*align-items: center;*/
    /*text-align: center;*/

    font-size: 18px;
}
.webinarInfoText {
}
.webinarCheckout {
    width: 50%;
}

.webinarProUsers {
    justify-content: center;
    align-items: center;
    text-align: center;

    padding: 20px;
}

.here button {
    color: white;
    font-weight: 600;
}
.paymentCancelled {
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 56px;
    /* identical to box height, or 117% */
    margin: 20px;
    text-align: center;

    letter-spacing: -1px;
    background: linear-gradient(270deg, #9f0202 0%, #7c0404 100%),
        linear-gradient(0deg, #c70000, #b41919);

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.container-webinar {
    display: flex;
}

@media (max-width: 991.98px) {
    .container-webinar {
        display: block;
        width: 100%;
    }
    .webinarInfo {
        width: 100%;
    }
    .webinarCheckout {
        width: 100%;
    }
}
