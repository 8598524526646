.new-time-button {
    margin-top: 10px;
    color: #388080;
    width: 100%;
    box-sizing: border-box;
    border-radius: 25px;
    border: 2px dotted #666;
    padding: 2px;
    text-align: center;
    background: inherit;
}
.new-time-button:hover {
    opacity: 0.8;
}

.settings {
    display: flex;
    gap: 15px;
}

.settings .icon-box {
    padding: 3px;
    border-radius: 5px;
    text-align: center;
}

.settings .icon-box:not(.selected) {
    cursor: pointer;
}

.settings .icon-box:not(.selected):hover {
    opacity: 0.8;
}

.settings .icon {
    color: white;
    margin-right: 0;
}

.settings .icon-box.selected .icon {
    color: #388080;
}

.settings button {
    margin-left: 30px;
}
