.equity-calculation button.start {
    width: 220px;
    height: 40px;
    border-radius: 20px;
    font-size: 1.1rem;
    color: white;
    background: #388080;
    text-transform: uppercase;
    margin: 20px auto;
    display: block;
}

.equity-calculation .equity-section-header {
    font-style: normal;
    font-weight: 800;
    font-size: 26px;
    line-height: 34px;
    margin: 10px 0px 10px 10px;
    margin-left: 0;
    text-align: left;
    display: inline-block;

    letter-spacing: -1px;
    background: linear-gradient(270deg, #ffc7a8 0%, #459393 100%),
        linear-gradient(0deg, #ffffff, #ffffff);

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.equity-calculation .equity-player-remove {
    border: none;
    color: white;
    background: inherit !important;
    width: 16px !important;
    height: 16px !important;
}

.equity-calculation .equity-player-remove svg {
    z-index: 0;
}

.equity-calculation .equity-frequency-input {
    color: white;
    margin: 10px 0;
}
