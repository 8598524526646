/* custom-styles.css */
.react-grid-Grid {
    background-color: #f0f0f0 !important; /* Zmiana tła tabeli */
    border: 0px solid red !important; /* Zmiana ramki */
}

.row-class {
    background: transparent;
    border: 0;
}
.row-class:hover,
.row-class-2:hover {
    background: rgba(56, 128, 128, 0.5);
    opacity: 0.7;
}

.row-class-2 {
    background: #292c33;
    border: 0;
}

.react-grid-HeaderCell {
    background-color: lightgray !important;
    /* Dodaj tutaj inne style dla nagłówków */
}

.rdg-header-sort-name {
    font-size: 14px;
    text-transform: uppercase;
}

.rdg-cell {
    width: 100% !important;
    align-content: center;
    --rdg-selection-color: none;
}
