.checkbox-wrapper-13 input[type='checkbox'] {
    --active: #388080;
    --active-inner: #fff;
    --focus: 1px rgba(19, 161, 135, 0.3);
    --border: #667793;
    --border-hover: #388080;
    --background: transparent;
    --disabled: #f6f8ff;
    --disabled-inner: #e1e6f9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 21px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition:
        background 0.1s,
        border-color 0.2s,
        box-shadow 0.2s;
}
.checkbox-wrapper-13 input[type='checkbox']:after {
    content: '';
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition:
        transform var(--d-t, 0.3s) var(--d-t-e, ease),
        opacity var(--d-o, 0.2s);
}
.checkbox-wrapper-13 input[type='checkbox']:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: 0.3s;
    --d-t: 0.6s;
    --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
}
.checkbox-wrapper-13 input[type='checkbox']:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;
}
.checkbox-wrapper-13 input[type='checkbox']:disabled:checked {
    --b: var(--disabled-inner);
    --bc: var(--border);
}
.checkbox-wrapper-13 input[type='checkbox']:disabled + label {
    cursor: not-allowed;
}
.checkbox-wrapper-13 input[type='checkbox']:hover:not(:checked):not(:disabled) {
    --bc: var(--border-hover);
}
.checkbox-wrapper-13 input[type='checkbox']:focus {
    box-shadow: 0 0 0 var(--focus);
}
.checkbox-wrapper-13 input[type='checkbox']:not(.switch) {
    width: 21px;
}
.checkbox-wrapper-13 input[type='checkbox']:not(.switch):after {
    opacity: var(--o, 0);
}
.checkbox-wrapper-13 input[type='checkbox']:not(.switch):checked {
    --o: 1;
}
.checkbox-wrapper-13 input[type='checkbox'] + label {
    //display: inline-block;
    //vertical-align: middle;
    cursor: pointer;
    margin-left: 4px;
}

.checkbox-wrapper-13 input[type='checkbox']:not(.switch) {
    border-radius: 8px;
}
.checkbox-wrapper-13 input[type='checkbox']:not(.switch):after {
    width: 5px;
    height: 9px;
    border: 2px solid var(--active-inner);
    border-top: 0;
    border-left: 0;
    left: 7px;
    top: 4px;
    transform: rotate(var(--r, 20deg));
}
.checkbox-wrapper-13 input[type='checkbox']:not(.switch):checked {
    --r: 43deg;
}

.checkbox-wrapper-13 * {
    box-sizing: inherit;
}
.checkbox-wrapper-13 *:before,
.checkbox-wrapper-13 *:after {
    box-sizing: inherit;
}
.checkbox-wrapper-13 {
    //background: red;
    display: flex;
    align-content: center;
}
