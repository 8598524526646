.time-description {
    display: flex;
    justify-content: space-between;
}

.time-description div:last-child {
    margin-left: auto;
    margin-top: auto;
}

.time-description button {
    padding: 5px 10px;
    background: #388080;
    border-radius: 5px;
    text-transform: uppercase;
    color: white;
}

.accordion-toggler {
    cursor: pointer;
}

.icon {
    color: #ffffff;
    margin-right: 20px;
    font-size: 18px;
    min-width: 30px;
}

.icon:hover {
    opacity: 0.8;
    cursor: pointer;
}
