// Colors
$label-colour: #25282c;
$disabled-colour: #ddd;
$toggle-colour: #388080;
$white: #fff;
$dot-colour: #f4f4f4;
$border-colour: #388080;
$focus-color: #ff0;

.toggle-switch {
    position: relative;
    margin-right: 10px;
    width: 55px;
    display: inline-block;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    text-align: left;
    border: 1px solid $border-colour;
    border-radius: 20px;

    &-checkbox {
        display: none;
    }

    &-label {
        display: block;
        overflow: hidden;
        cursor: pointer;
        border: 0 solid $label-colour;
        border-radius: 20px;
        margin: 0;

        &:focus {
            outline: none;

            > span {
                box-shadow: 0 0 2px 5px red;
            }
        }

        > span:focus {
            outline: none;
        }
    }

    &-inner {
        display: block;
        width: 200%;
        margin-left: -100%;
        transition: margin 0.2s ease-in 0s;

        &:before,
        &:after {
            display: block;
            float: left;
            width: 50%;
            height: 23px;
            padding: 0;
            line-height: 23px;
            font-size: 14px;
            color: white;
            font-weight: bold;
            box-sizing: border-box;
        }

        &:before {
            content: attr(data-yes);
            text-transform: uppercase;
            padding-left: 10px;
            background-color: $toggle-colour;

            opacity: 0.4;

            color: $dot-colour;
        }
    }

    &-disabled {
        background-color: $disabled-colour;
        cursor: not-allowed;

        &:before {
            background-color: $disabled-colour;
            cursor: not-allowed;
        }
    }

    &-inner:after {
        content: attr(data-no);
        text-transform: uppercase;
        padding-right: 10px;
        //background-color: $label-colour;
        color: $dot-colour;
        text-align: right;
    }

    &-switch {
        display: block;
        width: 13px;
        margin: 5px;
        background: $dot-colour;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 28px;
        border: 0px solid $label-colour;
        border-radius: 20px;
        transition: all 0.2s ease-in 0s;
    }

    &-checkbox:checked + &-label {
        .toggle-switch-inner {
            margin-left: 0;
        }

        .toggle-switch-switch {
            right: 0px;
        }
    }

    &.small-switch {
        width: 40px;

        .toggle-switch-inner {
            &:after,
            &:before {
                content: '';
                height: 20px;
                line-height: 20px;
            }
        }

        .toggle-switch-switch {
            width: 16px;
            right: 20px;
            margin: 2px;
        }
    }

    @media screen and (max-width: 991px) {
        transform: scale(0.9);
    }
    @media screen and (max-width: 767px) {
        transform: scale(0.825);
    }
    @media screen and (max-width: 575px) {
        transform: scale(0.75);
    }
}
