.nav-single-select-container {
    min-width: 18%;
}

.nav-w-40 {
    width: 40%;
}

.nav-single-select-container:not(:last-of-type) {
    margin-right: 10px;
}

.sim-prefix {
    font-size: 10px;
    /*color: grey;*/
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    margin-left: 8px;
}
.sim-disabled {
    color: grey;
}
